import PropTypes from "prop-types";
import classNames from "classnames";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
//import amber from "@mui/material/colors/amber";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import WarningIcon from "@mui/icons-material/Warning";
//import { withStyles } from "@mui/material/styles";
import Warning from "@mui/icons-material/Warning";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';

const variantIcon = {
  success: CheckCircleIcon,
  successDownload: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  root: {
    position: "relative",
    maxWidth: "100%"
  },
  success: {
    backgroundColor: "#4caf50" /*lightgreen" //green[600]*/,
    color: "white"
  },
  successDownload: {
    backgroundColor: "#4caf50" /*theme.palette.primary.dark*/,
    color: "white"
  },
  error: {
    backgroundColor: "#f44336" /*theme.palette.error.dark*/,
    color: "white"
  },
  info: {
    backgroundColor: "#2196f3" /*theme.palette.primary.dark*/,
    color: "white"
  },
  warning: {
    backgroundColor: "#ff9800" /*amber[700]*/,
    color: "white"
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  },
  progress: {
    color: "white"
  },
  displayText: {
    marginLeft: "1rem"
  }
});
// make this generic
const resolveTextsByType = type => {
  let displayText;
  let finishText;
  if (type === "upload") {
    displayText = "Uploading";
    finishText = "Upload complete!";
  }
  if (type === "download") {
    displayText = "Downloading";
    finishText = "Download complete!";
  }
  if (type === "security") {
    displayText = "Enabling security";
    finishText = "Security enabled, logout to see it in action!";
  }
  if (type === "usercreate") {
    displayText = "User profile updated";
    finishText = "User profile updated!";
  }
  if (type === "error") {
    displayText = "Ooops!";
    finishText = "An error occured";
  }
  if (type === "browser") {
    displayText = "Browser compatibility";
    finishText = "Metawebservice is best viewed in Google Chrome. Some aspects of the site might cause issues in other browsers.";
  }
  if (type === "delete") {
    displayText = "Page refresh";
    finishText = "Refresh page to get the lastest updates.";
  }
  if (type === "collect") {
    displayText = "Collect initiated";
    finishText = "File will be availeble in Uploads soon.!";
  }
  if (type === "SasExport") {
    displayText = "Exporting SAS files";
    finishText = "Go to Uploads to get the sasexport zip file.!";
  }
  if (type === "refresh") {
    displayText = "Refresh page to see the lastest updates";
    finishText = "Refresh page to get the lastest updates.";
  }
  return { displayText, finishText };
};

const progressLoader = props => {
  const { classes, progress } = props;

  if (progress && progress.lengthComputable) {
    return (
      <div>{`${Math.round((progress.loaded / progress.total) * 100)} %`}</div>
    );
  }
  return <CircularProgress size={20} className={classes.progress} />;
};

const progressNoLoader = props => {
  const { classes } = props;
  return <CircularProgress size={20} className={classes.progress} />;
}

const canStartTimer = props => {
  if (props.state === "finished") {
    return true;
  }

  return false;
}

function MySnackbarContent(props) {
  const {
    className,
    message,
    state,
    type,
    onClose,
    variant,
    progress,
    id,
    studyId,
    ...other
  } = props;

  const [timerStarted, setTimerStarted] = useState(false);
  const timer = null
  const timerRef = useRef(null);

  const initiateTimer = () => {
    // only used for error messages
    if (!timerStarted) {
      setTimerStarted(true)
      timer = setTimeout(() => closeItem(), 5000);
    }
  }

  useEffect(() => {
    if (!timerStarted && canStartTimer(props)) {
      initiateTimer();
    }

    return () => {
      clearTimeout(timerRef.current); // Cleanup on unmount
    };
  }, [timerStarted, props]);

  const closeItem = () => {
    clearTimeout(timerRef.current);
    onClose(id);
  }

  const Icon = variantIcon[variant];
  const { finishText, displayText } = resolveTextsByType(type);

  return (
    <SnackbarContent
      sx={styles1[variant] /*, styles1.root, className }*/}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" style={styles1.message}>
          {/*Initiate*/}
          {state === "initiate" &&
            <span style={styles1.displayText}>{message}</span>
          }
          {state === "initiateAuthError" &&
            <>
              <Warning /><span style={styles1.displayText}>{message}</span>
            </>
          }
          {state === "progress" && progressLoader(props)}
          {state === "progress" && (
            <span style={styles1.displayText}>{displayText}</span>
          )}

          {state === "finished" && (
            <Icon sx={(theme) => ({
              fontSize: 20,
              opacity: 0.9,
              marginRight: theme.spacing(1)
            })} />
          )}

          {state === "finished" && (
            <span style={styles1.displayText}>{finishText}</span>
          )}

          {/*Update*/}
          {state === "update" && progressNoLoader(props)}
          {state === "update" && (
            <span style={styles1.displayText}>{message}</span>
          )}

          {/*Final*/}
          {state === "final" && (
            <Icon sx={(theme) => ({
              fontSize: 20,
              opacity: 0.9,
              marginRight: theme.spacing(1)
            })} />
          )}
          {state === "final" && (
            <span style={styles1.displayText}>{message}</span>
          )}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          sx={styles1.close}
          onClick={closeItem}
        >
          <CloseIcon sx={styles1.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf([
    "success",
    "warning",
    "error",
    "info",
    "successDownload",
    "delete",
    "collect",
  ]).isRequired
};

const MySnackbarContentWrapper = MySnackbarContent;

function SnackbarList(props) {
  const {
    /*classes,
    className,
    message,
    state,*/
    onClose,
    /*variant,
    id,*/
    notifications
    /*...other*/
  } = props;

  return (
    <div key="da-wraper">
      {notifications.map((item, index) => (
        <MySnackbarContentWrapper key={index}
          variant={
            item.type === "upload"
              ? "success"
              : item.type === "error"
                ? "error"
                : item.type === "browser"
                  ? "error"
                  : item.type === "collect"
                    ? "success"
                    : "successDownload"
          }
          {...item}
          onClose={onClose}
        />
      ))}
    </div>
  );
}

const styles2 = /*theme => (*/{
  //margin: {
  //  margin: theme.spacing(1)
  //},
  wrapper: {
    position: "absolute",
    zIndex: 2000
  }
};

function ToastWrapper(props) {
  const { toasts, onClose } = props;

  return (
    <div style={styles2.wrapper}>
      <Snackbar
        key="snackbar-wrap"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        open={true}
        autoHideDuration={6000}
      >
        <SnackbarList notifications={toasts} onClose={onClose} />
      </Snackbar>
    </div>
  );
}


export default ToastWrapper;
