import React from "react";
import PropTypes from "prop-types";
import * as actions from "../../actions/";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

//import { withStyles } from "@mui/material/styles";
// import ModalTitle from "../components/ModalTitle";
import { viewModes } from "../../constants";
import { withRouter } from "react-router-dom";
//import DownloadFilesTable from "../components/DownloadFilesTable";
//import SasJobsListTable from "../components/SasJobsListTable";
//import { columnsDownload, columnsUpload } from "../../theme/TableStyles";
import { versionsTable } from "../../selectors/tableSelector";
import Modal from "./Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import classNames from "classnames";
import { CloudDownload } from '@mui/icons-material';
import IconButton from "@mui/material/IconButton";
//import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from 'react-redux';

const styles = theme => ({
  container: {
    border: "1px solid #e0dddd",
    marginTop: "1.5rem"
  },
  modalHeader: {
    margin: "0 0 0 1rem",
    ...theme.typography,
    fontSize: "1rem",
    color: "#FFE000"
  },
  paper: {
    borderRadius: "8px"
  },
  table: {
    minWidth: 600,
    fontFamily: theme.typography.fontFamily,
    borderRadius: '8px'
  },
  tableHeader: {
    background: "white",
    color: "black",
    "&:focus": {
      outline: 0
    },
    fontSize: "0.5rem",
    textTransform: "uppercase",
    borderTopRightRadius: '8px',
    borderRadius: '8px'

  },
  tableRowHead: {
    borderRadius: '8px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px'
  },
  tableCellHeader: {
    fontSize: "0.5rem",
    color: "black",
    textTransform: "uppercase",
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    border: '1px'
  },
  tableCellBody: {
    fontSize: "0.6rem"
  },
  minWidth: {
    minWidth: "4.5rem"
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    },
    height: "2.5rem",
    marginBottom: "0.5rem",
    width: "100%",
    position: "relative",
    borderTop: "1px dotted lightgrey",
    "&:last-of-type": {
      border: 0
    },
  },
});

//

// reload current view oíf delete files or update

const FileVersionsModal = () => {

  const {
    rows,
    tableLoading,
    cnt,
    allSelected,
    tableSort,
    viewMode
  } = useSelector(versionsTable);

  return (
    <Modal
      titleBorder
      fullWidth={true}
      maxWidth={"lg"}
      onClose={() => dispatch(actions.fileVersionsReset)}
      classes={styles}
      title={"File versions"}
      titleBackground
      open={true}
    >
      {tableLoading && <CircularProgress size={24} />}
      {(!tableLoading && viewMode !== viewModes.SASJOBS) && (
        <div className={styles.container}>
          <Table sx={styles.table} style={{ height: 300 }}>
            <TableHead sx={styles.tableHeader}>
              <TableRow sx={styles.tableRowHead}>
                <TableCell sx={styles.tableCellHeader} style={{ width: "350px" }}>
                  Name
                </TableCell>
                <TableCell sx={styles.tableCellHeader} style={{ width: "100px" }}>
                  Version
                </TableCell>
                <TableCell sx={styles.tableCellHeader} style={{ width: "180px" }}>
                  Created Date
                </TableCell>
                <TableCell sx={styles.tableCellHeader} align="center" style={{ width: "80px" }}>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => {
                return (
                  <TableRow key={row.id} sx={styles.row}>
                    <TableCell
                      sx={styles.tableCellBody}
                      component="th"
                      scope="row"
                    >
                      <b>{row.fileName}</b>
                    </TableCell>
                    <TableCell sx={styles.tableCellBody}>
                      {row.version}
                    </TableCell>
                    <TableCell sx={styles.tableCellBody}>
                      {row.created}
                    </TableCell>
                    <TableCell sx={styles.tableCellBody}>
                      <IconButton aria-label="Download" onClick={() => dispatch(actions.studyDownloadAllFiles(row.id))}>
                        <CloudDownload className="download--small"></CloudDownload>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              }
              )}
            </TableBody>
          </Table>
        </div>
      )}
      {/*(!tableLoading && viewMode === viewModes.SASJOBS) && (
          <div className={classes.container}>
            <SasJobsListTable
              actions={actions}
              rows={rows}
              sortColumns={()=>dispatch(actions.studySasTableSort}
              tableSort={tableSort}
              onAllToggled={()=>dispatch(actions.studyAllSASFilesToggles}
              onSasDownloadAll={()=>dispatch(actions.studyDownloadAllSasFiles}
              onSasJobToggled={()=>dispatch(actions.sasJobToggled}
              cnt={cnt}
              allSelected={allSelected}
            />
          </div>
        )*/}
    </Modal>
  );
}
/*
// we need a selector, route change should kill all modals
FileVersionsModal.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return versionsTable(state.fileVersions);
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)((FileVersionsModal));
*/

export default FileVersionsModal