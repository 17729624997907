import * as actions from "../actions";
import {
  takeLatest,
  call,
  put,
  getContext,
  select,
  delay
} from "redux-saga/effects";
import { getStudiesAndStudiesLoaded, getEmailAndCustomer, getStudyInviteState } from '../selectors/storeselectors';

// fecth study and get emails by sponsor and permissions if isAdmin
export function* fetchStudy(action) {
  try {
    //const state = yield select();
    const { studies, studiesLoaded } = yield select(getStudiesAndStudiesLoaded);
    //state.root;
    const { studyId } = action.payload;
    // todo: studies should be loaded before the item should be shown 
    if (studiesLoaded) {
      const study = studies.find(item => item.studyId === parseInt(studyId, 0));
      const { isAdminFlag } = study;
      const { email } = yield select(getEmailAndCustomer);//;state.userProfile; // for logged in user      

      if (isAdminFlag && study) {
        const api = yield getContext("api");
        const res = yield call(
          api.get,
          `customer/GetCustomerEmailsBySponsor/${studyId}`
        );
        yield put(actions.studyInviteStudyReceived(study, res || [], email));
        //const permissions = yield call(
        //  api.get,
        //  `StudyPermission/AssignStudyPermissions/${studyId}/${customerId}`
        //);
        const permissions = yield call(
          api.get,
          `StudyInvitation/Permissions`
        )

        yield put(
          //dispatch(actions.studyInvitePermissionsReceived(permissions.permissions)
          actions.studyInvitePermissionsReceived(permissions)
        );
      }
    } else {
      yield delay(50);
      yield put(actions.studyInviteFetchData(studyId));
    }
  } catch (error) {
    console.log("error in resp ", error);
  }
}

export function* inviteUser() {
  try {
    const api = yield getContext("api");
    //const state = yield select();
    const { studyId, userEmail, permissions } = yield select(getStudyInviteState);//state.studyInvite;
    //const { studyId, userEmail, permissions }

    const transFormedPermissions = permissions
      .filter(item => item.selected)
      .map(item => {
        const perm = { selected: true, PermissionId: item.permission };
        return perm;
      });
    const params = {
      Email: userEmail,
      StudyId: studyId,
      Permissions: transFormedPermissions
    };

    /*const emailSettings = */yield call(api.post, "StudyInvitation/InviteUser", params);



    yield put(actions.studyInviteSent());
  } catch (error) {
    console.log("error in resp ", error);
  }
}

export function* studyInviteSagas() {
  yield takeLatest(actions.STUDY_INVITE_FETCH_DATA, fetchStudy);
  yield takeLatest(actions.STUDY_INVITE_SUBMIT, inviteUser);
}
