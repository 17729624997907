import * as actions from '../actions';
import {
  takeLatest,
  /*take,*/
  delay,
  call,
  put,
  getContext,
  select,
  /*fork*/
} from "redux-saga/effects";
import AWS from "aws-sdk/global";
import S3 from 'aws-sdk/clients/s3';
import uniqueId from 'lodash/uniqueId';
//import JSZip from "jszip";
//import streamBrowserify from "stream-browserify";
//import buffer from "buffer";

import { formatIsoDate } from "../utils/date";
import { viewModes } from "../constants";
import { s3DownloadFile, getStudyPathAndCredentials } from './common';

function* getStudyFileVersions() {
  try {
    const api = yield getContext("api");
    const state = yield select();
    const { studyFileId, viewMode } = state.fileVersions;

    if (viewMode === viewModes.SASJOBS) {
      const sasjobsRes = yield call(api.get, `sas/GetSasJobVersions/${studyFileId}`);
      yield put(actions.fileVersionsReceived(sasjobsRes, studyFileId, viewMode));
    }
    else {
      const res = yield call(api.get, `StudyFile/GetStudyFileVersions/${studyFileId}`);
      yield put(actions.fileVersionsReceived(res, studyFileId, viewMode));
    }

  } catch (error) {
    yield put(actions.onApiError());
  }
}

function* downloadMultipleFilesV2(action) {
  const state = yield select();
  const api = yield getContext("api");
  const { id } = action.payload;
  const { studyDetail } = state.root;
  const { files, studyId, viewMode } = state.fileVersions;
  const idlist = id || files.filter(item => item.checkBox);
  //const since = formatIsoDate("");
  const params = {
    studyId: studyDetail.id,
    Destination: viewMode + ".zip",
    FileType: (viewMode !== "upload") ? "download" : "upload",
    StudyFileIds: [...new Set(idlist.map(item => item.id))]
  };

  const toastId = uniqueId('download');
  const d = new Date();
  const timestamp = d.toISOString().substring(0, 16);
  yield put(actions.uploadInitiated(toastId, studyDetail.id, 'download'));

  try {
    let file
    let fileName
    if (idlist.length > 1) {
      file = yield call(api.post, "StudyFile/Zip", params);
      fileName = `${studyDetail.studyName}_${timestamp}.zip`
    }
    else if (idlist.length === 1) {
      file = idlist[0]
      fileName = file.name
    }

    const since = formatIsoDate("");
    const obj = {
      studyId: studyId,
      UploadDownload: viewMode,
      studyFilesSince: since
    };

    const res = yield call(getStudyPathAndCredentials, {}, obj);

    AWS.config.update({
      accessKeyId: res.accessKeyId,
      secretAccessKey: res.secretAccessKey,
      sessionToken: res.sessionToken
    });

    let s3 = new S3({
      //let s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      region: "eu-west-1",
      sslEnabled: true,
      signatureVersion: "v4"
    });

    //const zipFileName = `${studyDetail.studyName}_${timestamp}.zip`;

    let S3GetObjectParams = {
      Bucket: file.s3BucketName,
      Key: file.s3Key,
      Expires: 600,
      ResponseContentDisposition: 'attachment; filename ="' + fileName + '"'
    };

    const dwnloadUrl = yield call(s3DownloadFile, { s3, S3GetObjectParams });
    const a = document.createElement('a')
    a.href = dwnloadUrl
    a.download = dwnloadUrl.split('/').pop()
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    yield put(actions.finalMessage(toastId, "Succesfully downloaded files.", "success"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  } catch (error) {
    console.log("error in resp ", error);
    yield put(actions.finalMessage(toastId, "Download failed!", "error"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  }
}

export function* fileVersionsSagas() {
  yield takeLatest(actions.FILE_VERSIONS_OPEN_MODAL, getStudyFileVersions);
  //yield takeLatest(actions.FILE_VERSIONS_DOWNLOAD_ALL, downloadMultipleFiles);
  yield takeLatest(actions.FILE_VERSIONS_DOWNLOAD_ALL, downloadMultipleFilesV2);
}
