import * as actions from "../actions";
import {
  takeLatest,
  take,
  call,
  all,
  put,
  getContext,
  select,
  fork,
  cancelled,
  delay,
  putResolve
} from "redux-saga/effects";

// https://sdk.amazonaws.com/builder/js/
import AWS from "aws-sdk/global";
import S3 from "aws-sdk/clients/s3";
import CryptoJS from "crypto-js";

import uniqueId from "lodash/uniqueId";
import { Buffer } from "buffer";
import { formatIsoDate } from "../utils/date";
import { uploadChannel } from "./uploadChannel";
import { get } from "lodash";

window.Buffer = Buffer

function* getStudiesSaga() {
  try {
    const api = yield getContext("api");
    const res = yield call(api.get, "Study/GetStudies"); // yxgwyijha7.execute-api.eu-west-1.amazonaws.com/dev/
    yield put(actions.studiesReceived(res ? res : []));
  } catch (error) {
    // dispatch a failure action to the store with the error
    console.log("error in resp ", error);
  }
}

function* getStudiesWithProjectsSaga() {
  try {
    const state = yield select();
    const { studies } = state.root;

    const studiesSorted = studies.sort(function (a, b) {
      if (!a.lastModifiedDate) {
        return a;
      }
      if (!b.lastModifiedDate) {
        return b;
      }

      return -a.lastModifiedDate.localeCompare(b.lastModifiedDate)
    })

    const limit = 3
    const chunked = chunked_array(studiesSorted, limit)
    //console.log(chunked)

    for (let items of chunked) {
      yield all(items.filter(study => Object.keys(study).some(key => key !== 'studyProject')).map(study => call(getSimpleStudy, study)));
      //console.log(items)
      yield delay(1800)
    }

  } catch (error) {
    // dispatch a failure action to the store with the error
    console.log("error in resp ", error);
  }
}

function* getStudiesSagaV2() {
  try {
    const api = yield getContext("api");
    const res = yield call(api.get, "Study/GetStudiesv2"); // yxgwyijha7.execute-api.eu-west-1.amazonaws.com/dev/
    yield put(actions.studiesReceivedv2(res ? res : []));
    return res;
  } catch (error) {
    // dispatch a failure action to the store with the error
    console.log("error in resp ", error);
  }
}

function* getSimpleStudy(params) {
  try {
    const api = yield getContext("api");
    const { studyId } = params;
    const study = yield call(api.get, `Study/GetSimple/${studyId}`);
    if (study && !study.status) {
      yield put(actions.receivedSimpleStudy(study))
    }
  }
  catch (error) {
    console.log("getSimpleStudy error", error)
  }
}

function* getAdminsOnStudy(action) {
  try {
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    const { studyId } = action.payload;
    //const { id } = studyDetail;
    const admins = yield call(api.get, `Customer/GetAdminsOnTrial/${studyId}`);

    if (admins && !admins.status) {
      yield put(actions.receivedAdminsOnStudy(admins))
    }
  }
  catch (error) { }
}



function* downloadMultipleFilesV2(action) {
  const toastId = uniqueId('downloadMultipleFilesV2');
  const api = yield getContext("api");
  const state = yield select();
  const { id } = action.payload;
  const { fileVersions } = state
  const { studyDetail } = state.root;
  const { fileType } = studyDetail
  //const { files } = fileVersions

  let filesVersioned = []
  const type = studyDetail.fileType + "Files"
  const currentFiles = studyDetail[type]

  //Unfortunately fileversions reside two places in state:
  // Files that can be opened are in studyDetails.fileVersions
  // Other files are in FileVersions.files
  if (id) {
    if (fileVersions.files.length > 0 && studyDetail.fileVersions.length === 0) {
      filesVersioned = fileVersions.files
    }
    else if (fileVersions.files.length === 0 && studyDetail.fileVersions.length > 0) {
      filesVersioned = studyDetail.fileVersions
    }

    //Making sure the download button works on individual files
    if (filesVersioned.length === 0) {
      filesVersioned = currentFiles.filter(item => item.id === id)
    }
  }

  var idlist = (id) ? filesVersioned.filter(item => item.id === id) : currentFiles.filter(item => item.checkBox)
  yield put(actions.initiateMessage(toastId, studyDetail.id, 'Starting to download ' + idlist.length + " files."));

  const params = {
    studyId: studyDetail.id,
    Destination: fileType + ".zip",
    UploadDownload: (fileType !== "upload") ? "download" : "upload",
    StudyFileIds: [...new Set(idlist.map(item => item.id))]
  };

  try {

    let file
    let fileName
    const d = new Date();
    const timestamp = d.toISOString().substring(0, 16);

    if (idlist.length > 1) {
      file = yield call(api.post, "StudyFile/Zip", params)
      fileName = `${studyDetail.studyName}_${timestamp}.zip`
    }

    else if (idlist.length === 1) {
      file = yield call(api.get, `StudyFile/GetStudyFile/${idlist[0].id}`);
      fileName = idlist[0].fileName
    }

    const since = formatIsoDate("");
    const obj = {
      studyId: studyDetail.id,
      UploadDownload: fileType,
      studyFilesSince: since
    };

    const res = yield call(getStudyPathAndCredentials, {}, obj);

    AWS.config.httpOptions.timeout = 0;
    AWS.config.update({
      accessKeyId: res.accessKeyId,
      secretAccessKey: res.secretAccessKey,
      sessionToken: res.sessionToken
    });
    //let s3 = new AWS.S3({
    let s3 = new S3({
      apiVersion: "2006-03-01",
      region: "eu-west-1",
      sslEnabled: true,
      signatureVersion: "v4"
    });

    let S3GetObjectParams = {
      Bucket: file.s3BucketName,
      Key: file.s3Key,
      Expires: 600,
      ResponseContentDisposition: 'attachment; filename ="' + fileName + '"'
    };

    const dwnloadUrl = yield call(s3DownloadFile, { s3, S3GetObjectParams });

    const a = document.createElement('a')
    a.href = dwnloadUrl
    a.download = dwnloadUrl.split('/').pop()
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    AWS.config.httpOptions.timeout = 120000;
    yield put(actions.finalMessage(toastId, "Succesfully downloaded files.", "success"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  } catch (error) {
    AWS.config.httpOptions.timeout = 120000;
    console.log("error in resp ", error);
    yield put(actions.finalMessage(toastId, "Download failed!", "error"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  }
}

function* downloadMultipleSasFilesV2(action) {
  const toastId = uniqueId('downloadMultipleSasFilesV2');
  const api = yield getContext("api");
  const state = yield select();
  const { id, fileName, folder } = action.payload;
  const { studyDetail } = state.root;
  const { sasjobsFiles } = studyDetail;

  console.log("Started downloadMultipleSasFilesV2")
  console.log(id, fileName, folder)
  let sasjobsfetched = []

  if (id !== undefined) {
    sasjobsfetched.push({
      id: id,
      type: folder,
      name: fileName,
      checkBox: true
    })
  }
  else {
    sasjobsfetched = sasjobsFiles
  }


  var idlist
  idlist = sasjobsfetched.filter(item => item.checkBox);

  yield put(actions.initiateMessage(toastId, studyDetail.id, 'Starting to download ' + idlist.length + " files."));

  const params = {
    studyId: studyDetail.id,
    Destination: "zippedsas.zip",
    SasJobIds: [...new Set(idlist.map(item => item.id))]
  };

  try {

    let file
    let fileName
    const d = new Date();
    const timestamp = d.toISOString().substring(0, 16);

    if (idlist.length > 1) {
      file = yield call(api.post, "Sas/Zip", params);
      fileName = `${studyDetail.studyName}_${timestamp}.zip`
      const since = formatIsoDate("");
      const obj = {
        studyId: studyDetail.id,
        UploadDownload: "download",
        studyFilesSince: since
      };

      const res = yield call(getStudyPathAndCredentials, {}, obj);

      AWS.config.httpOptions.timeout = 0;
      AWS.config.update({
        accessKeyId: res.accessKeyId,
        secretAccessKey: res.secretAccessKey,
        sessionToken: res.sessionToken
      });
      //let s3 = new AWS.S3({
      let s3 = new S3({
        apiVersion: "2006-03-01",
        region: "eu-west-1",
        sslEnabled: true,
        signatureVersion: "v4"
      });

      //const d = new Date();
      //const timestamp = d.toISOString().substring(0, 16);
      //const zipFileName = `${studyDetail.studyName}_${timestamp}.zip`;
      let S3GetObjectParams = {
        Bucket: file.s3BucketName,
        Key: file.s3Key,
        Expires: 600,
        ResponseContentDisposition: 'attachment; filename ="' + fileName + '"'
      };

      console.log(S3GetObjectParams)
      const dwnloadUrl = yield call(s3DownloadFile, { s3, S3GetObjectParams });

      const a = document.createElement('a')
      a.href = dwnloadUrl
      a.download = dwnloadUrl.split('/').pop()
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

    }

    else if (idlist.length === 1) {
      file = idlist[0]
      const job = yield call(api.get, `sas/Job/${file.id}`);
      const filepath = job.folder + "/" + job.fileName
      const lastModified = new Date(job.sasJobCreatedDate)

      let sasfile = new File([job.sasCode], filepath, { type: "", lastModified: lastModified })

      var a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(sasfile);
      a.download = job.fileName;
      // Append anchor to body.
      document.body.appendChild(a);
      a.click();
      // Remove anchor from body
      document.body.removeChild(a);
    }
    AWS.config.httpOptions.timeout = 120000;
    yield put(actions.finalMessage(toastId, "Succesfully downloaded files.", "success"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  } catch (error) {
    AWS.config.httpOptions.timeout = 120000;
    console.log("error in resp ", error);
    yield put(actions.finalMessage(toastId, "Download failed!", "error"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  }
}


export function* getStudyPathAndCredentials(action, obj) {

  try {
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;

    const _params = {
      "studyId": studyDetail.id,
      "UploadDownload": obj.UploadDownload
    }
    const { type } = _params

    const res = yield call(
      api.post,
      "credentials/GetStudyCredentialsV2",
      _params
    );

    return { ...res, type };
  } catch (error) {
    console.log("error ", error);
  }
}

function* getSasJobFileFoldersByFolder(folder) {
  try {
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    const { id } = studyDetail;

    //yield put(actions.emptySasJobFileFolders())
    const res = yield call(api.get, `sas/ListFolders/${id}`);
    yield put(actions.sasJobFoldersReceived(res ? res : []));
    //yield call(loadSasJobsByFolderAsync);
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}

function* refreshSasJobFileFolder(action, props = null) {

  const { folders } = action.payload;
  console.log(folders)
  yield put(actions.startRefreshFolderFiles())

  if (folders.length > 0) {
    let folder = folders[0].replace(/#/g, "/")
    yield call(getSasJobFileFoldersByFolder, folder)
    yield call(getSasJobROByFolderPaging, folder, 1, 50);
  }
  yield put(actions.setSasJobFolder(folders))
}

function* getStudyFileFolders(action, props = null) {
  try {

    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    const { selectedUploadDownloadfolder, fileType } = studyDetail;

    if (!selectedUploadDownloadfolder || selectedUploadDownloadfolder.length === 0) {

      //Fetch all folders
      yield put(actions.emptyStudyFileFolders(fileType))
      yield call(loadFilesByFolderAsync);

      let params = {
        studyId: studyDetail.id,
        uploadDownload: (fileType.toUpperCase() !== "RELEASE") ? fileType : "download",
        studyFilesSince: new Date().toISOString(),
        release: (fileType.toUpperCase() === "RELEASE") ? true : false,
        Page: 1,
        ResultsPrPage: 200
      };

      //Folders fetched
      const res = yield call(api.post, "Study/GetStudyFileFolders", params);
      yield put(actions.studyFileFoldersReceived(res || [], fileType));

      //Check for configuration file
      const confExists = res.filter(folder => folder.folder.toUpperCase() === "CONFIGURATION").length > 0
      if (confExists) {
        let obj = {
          "studyId": studyDetail.id,
          "UploadDownload": (fileType.toUpperCase() !== "RELEASE") ? fileType : "download",
          "Folder": "CONFIGURATION",
          "studyFilesSince": new Date().toISOString(),
          "release": false,
          "Page": 1,
          "ResultsPrPage": 5
        };
        const payload = yield call(api.post, "Study/GetStudyFiles", obj);

        const configurationFile = payload.studyfiles[0]
        yield put(actions.getJson(configurationFile, "CONFIG"))
      }
    }

    else {
      //Only update the folders in selectedUploadDownloadfolder
      yield call(loadFilesByFolderAsync);

      for (let folderItem of selectedUploadDownloadfolder) {
        let folder = folderItem;
        let params = {
          studyId: studyDetail.id,
          UploadDownload: (fileType.toUpperCase() !== "RELEASE") ? fileType : "download",
          studyFilesSince: new Date().toISOString(),
          release: (fileType.toUpperCase() === "RELEASE") ? true : false,
          Page: 1,
          Folder: folder,
          ResultsPrPage: 2
        };
        const res = yield call(api.post, "Study/GetStudyFileFolders", params);
        yield put(actions.studyFileFolderReceived(res || [], fileType, folderItem));
      }
    }
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* getStudyFileFoldersWithFolder(action, props = null) {
  try {

    const api = yield getContext("api");
    const state = yield select();
    const { folder, status } = action.payload
    const { studyDetail } = state.root;
    const { fileType } = studyDetail;


    yield put(actions.setStudyFileFolder(folder))

    if (status) {
      yield put(actions.studyChangeFilterInit(status))
    }

    yield put(actions.emptyStudyFileFolders(fileType))
    yield call(loadFilesByFolderAsync);

    let params = {
      studyId: studyDetail.id,
      uploadDownload: (fileType.toUpperCase() !== "RELEASE") ? fileType : "download",
      studyFilesSince: new Date().toISOString(),
      release: (fileType.toUpperCase() === "RELEASE") ? true : false,
      Page: 1,
      ResultsPrPage: 200
    };

    //Folders fetched
    const res = yield call(api.post, "Study/GetStudyFileFolders", params);
    yield put(actions.studyFileFoldersReceived(res || [], fileType));

    //Check for configuration file
    const confExists = res.filter(folder => folder.folder.toUpperCase() === "CONFIGURATION").length > 0
    if (confExists) {
      let obj = {
        "studyId": studyDetail.id,
        "type": (fileType.toUpperCase() !== "RELEASE") ? fileType : "download",
        "folder": "CONFIGURATION",
        "studyFilesSince": new Date().toISOString(),
        "release": false,
        "Page": 1,
        "ResultsPrPage": 5
      };
      const payload = yield call(api.post, "Study/GetStudyFilesByType", obj);

      const configurationFile = payload.studyfiles[0]
      yield put(actions.getJson(configurationFile, "CONFIG"))
    }
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}

function* getSasJobFileFolders(action, props = null) {
  try {
    //const api = yield getContext("api");
    //const state = yield select();
    //const { studyDetail } = state.root;
    //const { id /*, selectedSasJobfolders*/ } = studyDetail;

    yield put(actions.emptySasJobFileFolders())
    yield call(loadSasJobsByFolderAsync);

    //const res = yield call(api.get, `sas/ListFolders/${id}`);
    //yield put(actions.sasJobFoldersReceived(res ? res : []));

  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* loadFilesByFolderAsync(action) {
  const state = yield select();
  const { studyDetail } = state.root;
  let { fileType, selectedUploadDownloadfolder } = studyDetail;
  try {
    let folder = undefined
    if (selectedUploadDownloadfolder && selectedUploadDownloadfolder.length > 0) {
      folder = selectedUploadDownloadfolder[0];
    }
    if (fileType === "download" || fileType === "upload") {
      yield fork(getStudyFileROByFolderPaging, folder, 1, 50);
    }
    else if (fileType === "release") {
      //yield fork(getStudyFileByFolderPaging, folder);
      yield fork(getStudyReleaseFileByFolderPaging, folder, 1, 50);
    }
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}

function* loadSasJobsByFolderAsync(action) {
  try {
    const state = yield select();
    const { studyDetail } = state.root;
    let { selectedSasJobfolders } = studyDetail;
    let folder = undefined
    if (selectedSasJobfolders && selectedSasJobfolders.length > 0) {
      folder = selectedSasJobfolders[0];
    }
    yield fork(getSasJobROByFolderPaging, folder, 1, 50);
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}

function* getStudyFileROByFolderPaging(folder, page, numPrPage) {
  try {

    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    console.log(studyDetail)
    const { fileType, filter, isSasUserToggledOn } = studyDetail;

    const regex = fileType + "RegexExpression"
    const searchQuery = (studyDetail[regex]) ? studyDetail[regex] : undefined

    yield put(actions.studyFileResetPagingWithFolder(folder, fileType));
    let res = []

    const uploadDownload = (fileType.toUpperCase() !== "RELEASE") ? fileType : "download"
    const sort = "DESCENDING"

    //do {
    let url = `Report/GetFiles?studyId=${studyDetail.id}&uploadDownload=${uploadDownload}`

    if (folder) {
      url = url.concat(`&folder=${encodeURIComponent(folder)}`)
    }
    if (searchQuery) {
      url = url.concat(`&searchQuery=${encodeURIComponent(searchQuery)}`)
    }
    if (filter && filter.length > 0) {
      url = url.concat(`&status=${filter[0]}`)
    }
    if (fileType.toUpperCase() === "RELEASE") {
      url = url.concat(`&release=${true}`)
    }
    if (fileType.toUpperCase() === "DOWNLOAD") {
      url = url.concat(`&isSasUser=${isSasUserToggledOn}`)
    }

    url = url.concat(`&sort=${sort}&pageNumber=${page}&pageSize=${numPrPage}`)
    res = yield call(api.get, url);
    yield put(actions.studyUploadsReceivedPaging(res, fileType));
    yield put(actions.getStudyUploadsFinished(folder))
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* getStudyReleaseFileByFolderPaging(folder, page, numPrPage) {
  try {

    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    const { fileType } = studyDetail;

    const regex = fileType + "RegexExpression"
    const searchQuery = (studyDetail[regex]) ? studyDetail[regex] : undefined

    yield put(actions.studyFileResetPagingWithFolder(folder, fileType));
    let res = []

    //do {
    let url = `Report/GetReleasedFiles?studyId=${studyDetail.id}`
    if (folder) {
      url = url.concat(`&folder=${encodeURIComponent(folder)}`)
    }
    if (searchQuery) {
      url = url.concat(`&searchQuery=${encodeURIComponent(searchQuery)}`)
    }

    url = url.concat(`&pageNumber=${page}&pageSize=${numPrPage}`)
    res = yield call(api.get, url);
    yield put(actions.studyUploadsReceivedPaging(res, fileType));
    yield put(actions.getStudyUploadsFinished(folder))
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* getSasJobROByFolderPaging(folder, page, numPrPage) {
  try {

    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    const { fileType, sasfilter } = studyDetail;

    const regex = fileType + "RegexExpression"
    const searchQuery = (studyDetail[regex]) ? studyDetail[regex] : undefined

    yield put(actions.sasJobsResetPaging(folder));

    let res = []

    const sort = "DESCENDING"

    //do {
    let url = `Report/GetJobs?studyId=${studyDetail.id}`
    if (folder) {
      url = url.concat(`&folder=${encodeURIComponent(folder)}`)
    }
    if (searchQuery) {
      url = url.concat(`&searchQuery=${encodeURIComponent(searchQuery)}`)
    }

    if (sasfilter && sasfilter["sasRunStatus"] && sasfilter["sasRunStatus"].length > 0) {
      const sasrunstatus = sasfilter["sasRunStatus"][0]
      url = url.concat(`&runstatus=${sasrunstatus}`)
    }
    if (sasfilter && sasfilter["qcStatus"] && sasfilter["qcStatus"].length > 0) {
      const qcstatus = sasfilter["qcStatus"][0]
      url = url.concat(`&qcstatus=${qcstatus}`)
    }

    if (sasfilter && sasfilter["runStatus"] && sasfilter["runStatus"].length > 0) {
      let runstatus = sasfilter["runStatus"][0]
      if (runstatus.toLowerCase() === "not started") {
        runstatus = "notstarted"
      }
      url = url.concat(`&executionStatus=${runstatus}`)
    }

    url = url.concat(`&sort=${sort}&pageNumber=${page}&pageSize=${numPrPage}`)
    res = yield call(api.get, url);
    yield put(actions.sasJobsReceivedPaging(res));

    //Get folder before QC jobs
    const resFolder = yield call(api.get, `sas/ListFolders/${studyDetail.id}`);
    yield put(actions.sasJobFoldersReceived(resFolder ? resFolder : []));

    //Start QC pull of metadata for all jobs   
    if (res.data && res.data.length > 0) {
      const limit = 5
      const filtered = res.data.filter(job => !job.folder.endsWith('/qc'))
      const chunked = chunked_array(filtered, limit)
      for (let items of chunked) {
        yield all(items.map(arr => call(getQcJob, arr)));
        yield delay(300)
      }
    }
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* getStudyFileROPaging(action) {
  try {
    const { folder, page, numPrPage } = action.payload
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    const { fileType, filter } = studyDetail;

    const regex = fileType + "RegexExpression"
    const searchQuery = (studyDetail[regex]) ? studyDetail[regex] : undefined

    yield put(actions.studyFileResetPagingWithFolder(folder, fileType));
    let res = []

    const uploadDownload = (fileType.toUpperCase() !== "RELEASE") ? fileType : "download"
    const sort = "DESCENDING"

    //do {
    let url = `Report/GetFiles?studyId=${studyDetail.id}&uploadDownload=${uploadDownload}`
    if (folder) {
      url = url.concat(`&folder=${encodeURIComponent(folder)}`)
    }
    if (searchQuery) {
      url = url.concat(`&searchQuery=${encodeURIComponent(searchQuery)}`)
    }
    if (filter && filter.length > 0) {
      url = url.concat(`&status=${filter[0]}`)
    }

    if (fileType.toUpperCase() === "RELEASE") {
      url = url.concat(`&release=${true}`)
    }

    url = url.concat(`&sort=${sort}&pageNumber=${page}&pageSize=${numPrPage}`)
    res = yield call(api.get, url);
    yield put(actions.studyUploadsReceivedPaging(res, fileType));
    yield put(actions.getStudyUploadsFinished(folder))
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* getStudyReleaseFilePaging(action) {
  try {
    const { folder, page, numPrPage } = action.payload
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    const { fileType } = studyDetail;

    const regex = fileType + "RegexExpression"
    const searchQuery = (studyDetail[regex]) ? studyDetail[regex] : undefined

    yield put(actions.studyFileResetPagingWithFolder(folder, fileType));
    let res = []

    //do {
    let url = `Report/GetReleasedFiles?studyId=${studyDetail.id}`
    if (folder) {
      url = url.concat(`&folder=${encodeURIComponent(folder)}`)
    }
    if (searchQuery) {
      url = url.concat(`&searchQuery=${encodeURIComponent(searchQuery)}`)
    }

    url = url.concat(`&pageNumber=${page}&pageSize=${numPrPage}`)
    res = yield call(api.get, url);
    yield put(actions.studyUploadsReceivedPaging(res, fileType));
    yield put(actions.getStudyUploadsFinished(folder))
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* getSasjobROPaging(action) {
  try {
    const { folder, page, numPrPage } = action.payload
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    const { fileType, sasfilter } = studyDetail;

    const regex = fileType + "RegexExpression"
    const searchQuery = (studyDetail[regex]) ? studyDetail[regex] : undefined

    yield put(actions.sasJobsResetPaging(folder));
    //yield put(actions.sasJobsResetPaging(folder + "/qc"));

    let res = []

    const sort = "DESCENDING"

    //do {
    let url = `Report/GetJobs?studyId=${studyDetail.id}`
    if (folder) {
      url = url.concat(`&folder=${encodeURIComponent(folder)}`)
    }
    if (searchQuery) {
      url = url.concat(`&searchQuery=${encodeURIComponent(searchQuery)}`)
    }
    if (sasfilter && sasfilter["sasRunStatus"] && sasfilter["sasRunStatus"].length > 0) {
      const sasrunstatus = sasfilter["sasRunStatus"][0]
      url = url.concat(`&runstatus=${sasrunstatus}`)
    }
    if (sasfilter && sasfilter["qcStatus"] && sasfilter["qcStatus"].length > 0) {
      const qcstatus = sasfilter["qcStatus"][0]
      url = url.concat(`&qcstatus=${qcstatus}`)
    }

    if (sasfilter && sasfilter["runStatus"] && sasfilter["runStatus"].length > 0) {
      let runstatus = sasfilter["runStatus"][0]
      if (runstatus.toLowerCase() === "not started") {
        runstatus = "notstarted"
      }
      url = url.concat(`&executionStatus=${runstatus}`)
    }

    url = url.concat(`&sort=${sort}&pageNumber=${page}&pageSize=${numPrPage}`)
    res = yield call(api.get, url);
    yield put(actions.sasJobsReceivedPaging(res));

    //Start pull of metadata for all jobs

    if (res.data && res.data.length > 0) {
      const limit = 5
      const filtered = res.data.filter(job => !job.folder.endsWith('/qc'))
      const chunked = chunked_array(filtered, limit)
      //Start pull of metadata for all jobs      
      for (let items of chunked) {
        yield all(items.map(arr => call(getQcJob, arr)));
        yield delay(300)
      }
    }
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* getStudyFileFolder(folder) {
  try {
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    const { fileType } = studyDetail;

    let params = {
      studyId: studyDetail.id,
      uploadDownload: (fileType.toUpperCase() !== "RELEASE") ? fileType : "download",
      studyFilesSince: new Date().toISOString(),
      release: (fileType.toUpperCase() === "RELEASE") ? true : false,
      Page: 1,
      folder: folder,
      ResultsPrPage: 2
    };

    const res = yield call(api.post, "Study/GetStudyFileFolders", params);
    yield put(actions.studyFileFolderReceived(res || [], fileType, folder));
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* getQcJob(params) {
  try {
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    const { sasJobId } = params;
    const qcJob = yield call(api.get, `Report/GetQcJob/${studyDetail.id}/${sasJobId}`);
    if (qcJob && !qcJob.status) {
      yield put(actions.receivedQcJob(qcJob))
    }
  }
  catch (error) { }
}

function* changeStatusAndFetch(action) {
  try {
    console.log(action.payload)
    const { folder, page, numPrPage, value } = action.payload
    const state = yield select();
    const { studyDetail } = state.root;
    const { fileType } = studyDetail;
    if (fileType.toLowerCase() === "download") {
      yield put(actions.studyChangeFilterInit(value));
      yield call(getStudyFileROByFolderPaging, folder, page, numPrPage);
    } else {
      if (value === "RUN" || value === "NOTSTARTED" || value === "RUNNING") {
        yield put(actions.sasChangeFilter(value, "runStatus"));
      }
      yield put(actions.sasChangeFilter(value, "sasRunStatus"));
      yield call(getSasJobROByFolderPaging, folder, page, numPrPage);
    }
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* changeViewAndFetch(action) {
  try {
    const { folder, page, numPrPage, value } = action.payload
    const state = yield select();
    const { studyDetail } = state.root;
    const { fileType, isSasUserToggledOn } = studyDetail;
    if (fileType.toLowerCase() === "download") {
      yield put(actions.isSasUserToggledOnChanged(!value));
      yield call(getStudyFileROByFolderPaging, folder, page, numPrPage);
    }
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* refreshStudyFileFolder(action, props = null) {
  try {
    const { folders } = action.payload;
    const state = yield select();
    const { studyDetail } = state.root;
    const {
      fileType,
    } = studyDetail;

    yield put(actions.startRefreshFolderFiles())

    //Only check if one folder is selected - in the future one could check each folder if multiple are selected
    if (folders.length > 0) {
      //let folder = folders[0].replace("#", "/")
      let folder = folders[0].replace(/#/g, "/")

      if (fileType.toUpperCase() !== "RELEASE") {
        yield call(getStudyFileFolder, folder)
        yield call(getStudyFileROByFolderPaging, folder, 1, 50);
      }
      else {
        yield call(getStudyReleaseFileByFolderPaging, folder, 1, 50);
      }
    }
    yield put(actions.setStudyFileFolder(folders))
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}

function* clearSelectedFolder(action) {
  try {
    const { folder } = action.payload;
    const state = yield select();
    const { studyDetail } = state.root;
    const { fileType } = studyDetail;

    yield put(actions.clearSelectedFolderBegin(folder))
    yield put(actions.startRefreshFolderFiles())

    if (fileType.toUpperCase() !== "RELEASE") {
      yield put(actions.getStudyFileFolders())
      yield call(getStudyFileROByFolderPaging, undefined, 1, 50);
    }
    else {
      yield call(getStudyReleaseFileByFolderPaging, undefined, 1, 50);
    }
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}
function* clearSelectedSasFolder(action) {
  try {
    const { folder } = action.payload;
    yield put(actions.clearSelectedSasFolderBegin(folder))
    //yield put(actions.startRefreshSasFolderFiles())
    yield call(getSasJobROByFolderPaging, undefined, 1, 50);
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}

// //https://blog.shovonhasan.com/using-promises-with-filereader/ put in seperate helper file
const readUploadedFileAsArrayBuffer = inputFile => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.onloadend = () => {
      resolve(temporaryFileReader.result);
    };
    //temporaryFileReader.readAsText(inputFile);
    temporaryFileReader.readAsArrayBuffer(inputFile);
  });
};

export const s3DownloadFile = props => {
  return new Promise(function (resolve, reject) {
    props.s3.getSignedUrl("getObject", props.S3GetObjectParams, function (
      err,
      data
    ) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};

function* deleteFiles(action) {
  try {
    const api = yield getContext("api");
    const id = yield call(api.delete, `StudyFile/DeleteStudyFile/${action.payload.id}`);
    yield put(actions.deleteSuccess(id));
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}

function* deleteMultipleFiles(action) {
  const toastId = uniqueId('deleteMultipleFiles');
  try {
    const state = yield select();
    const { id } = action.payload;
    const { studyDetail } = state.root;
    const { fileType } = studyDetail;
    const api = yield getContext("api");

    const type = fileType + "Files"

    let idlist = id || studyDetail[type].filter(item => item.checkBox);

    //Starting toast     
    yield put(actions.initiateMessage(toastId, studyDetail.id, 'Starting to delete ' + idlist.length + " files."));

    //Updating toast with information
    let __count = 0
    for (let file of idlist) {
      const id = yield call(api.delete, `StudyFile/DeleteStudyFile/${file.id}`);
      if (id) {
        __count++;
        yield put(actions.updateMessage(toastId, "Deleting " + __count + " of " + idlist.length + " files."));
      }
    }

    //Finalizing toast
    if (__count === idlist.length) {
      yield put(actions.finalMessage(toastId, "Succesfully deleted files. Refreshing page!", "success"));
    }
    else {
      yield put(actions.finalMessage(toastId, "Deleted " + __count + " of " + idlist.length + " files. Refreshing page!", "warning"));
    }

    //Closing toast
    yield delay(2500);
    yield put(actions.toastClose(toastId));
    yield put(actions.getStudyFileFolders())
  }
  catch (error) {
    console.log("error in resp ", error);
    yield put(actions.finalMessage(toastId, "Delete failed!", "error"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  }
}

function* copyDirectLink(action) {
  const toastId = uniqueId('shareMultipleFiles');
  try {
    const state = yield select();
    const { folders } = action.payload
    console.log(folders)
    const { studyDetail } = state.root
    const { filter } = studyDetail;

    //Starting toast     
    yield put(actions.initiateMessage(toastId, studyDetail.id, 'Copying link to selected folder.'));
    let basePath = window.location.href
    if (basePath.includes('?') === true) {
      basePath = window.location.href.split('?')[0]
    }

    let link = `${basePath}?folder=${encodeURIComponent(folders[0])}`
    if (filter && filter.length !== 0) {
      link = `${link}&status=${filter[0]}`
    }

    navigator.clipboard.writeText(link)

    yield put(actions.finalMessage(toastId, "Copied link to clipboard", "success"));

    //Closing toast
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  }
  catch (error) {
    console.log("error in resp ", error);
    yield put(actions.finalMessage(toastId, "Copy failed!", "error"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  }
}


function* shareMultipleFiles(action) {
  const toastId = uniqueId('shareMultipleFiles');

  try {
    const state = yield select();
    const { id } = action.payload;
    const { studyDetail } = state.root;
    const { fileType } = studyDetail;
    const type = fileType + "Files"


    var idlist
    // todo: write a spec fo this
    //if (fileType === "download") {
    idlist = id || studyDetail[type].filter(item => item.checkBox);
    //}
    //else if (fileType === "upload") {
    //  idlist = id || uploadedFiles.filter(item => item.checkBox);
    //}

    //Starting toast     
    yield put(actions.initiateMessage(toastId, studyDetail.id, 'Copying ' + idlist.length + " files to clipboard."));

    //Updating toast with information
    let __count = 0
    let clipboardMessage = "";
    let basePath = window.location.href.split('/')
    basePath.pop()
    for (let file of idlist) {
      let link = `${basePath.join('/')}/studyFile/${studyDetail.fileType}/${file.id} \n`
      clipboardMessage = clipboardMessage + link
      __count++
    }


    //Finalizing toast
    if (__count === idlist.length) {
      navigator.clipboard.writeText(clipboardMessage)
      yield put(actions.finalMessage(toastId, "Copied files to clipboard", "success"));
    }

    //Closing toast
    yield delay(2500);
    yield put(actions.toastClose(toastId));
    //yield put(actions.getStudyFileFolders())
  }
  catch (error) {
    console.log("error in resp ", error);
    yield put(actions.finalMessage(toastId, "Copy failed!", "error"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  }
}

function* shareMultipleSasFiles(action) {
  const toastId = uniqueId('shareMultipleSasFiles');
  try {
    const state = yield select();
    //const { id } = action.payload;
    const { studyDetail } = state.root;
    const { sasjobsFiles } = studyDetail;

    var idlist
    idlist = sasjobsFiles.filter(item => item.checkBox);

    //Starting toast     
    yield put(actions.initiateMessage(toastId, studyDetail.id, 'Copying ' + idlist.length + " files to clipboard."));

    //Updating toast with information
    let __count = 0
    let clipboardMessage = "";
    let basePath = window.location.href.split('/')
    basePath.pop()

    for (let file of idlist) {
      let link = `${basePath.join('/')}/sasjob/${file.id} \n`
      clipboardMessage = clipboardMessage + link
      __count++
    }

    //Finalizing toast
    if (__count === idlist.length) {
      navigator.clipboard.writeText(clipboardMessage)
      yield put(actions.finalMessage(toastId, "Copied files to clipboard", "success"));
    }

    //Closing toast
    yield delay(2500);
    yield put(actions.toastClose(toastId));
    //yield put(actions.getStudyFileFolders())
  }
  catch (error) {
    console.log("error in resp ", error);
    yield put(actions.finalMessage(toastId, "Copy failed!", "error"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  }
}

function* uploadFileToBucket(props) {
  const channel = yield call(uploadChannel, props.s3, props.S3PutObjectParams);
  while (true) {
    try {
      const { progress = 0, err, } = yield take(channel);

      if (err) {
        yield put(actions.uploadFailed(props.toastId));
      }

      yield put(actions.uploadProgress(props.toastId, progress));

    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

function* uploadFiles(action) {
  const toastId = uniqueId('uploadFiles');

  try {
    const state = yield select();
    const { studyDetail } = state.root;
    const since = formatIsoDate("");

    const obj = {
      studyId: studyDetail.id,
      UploadDownload: resolveTypePayload(action.payload.folder),
      studyFilesSince: since
    };

    yield put(actions.studyCloseUploadModal());
    const { files, contentType, folder } = action.payload;
    yield put(actions.initiateMessage(toastId, studyDetail.id, 'Uploading ' + files.length + " files."));

    let uploadId = 0;
    let toastIds = [];
    for (let file of files) {
      const toastId = uniqueId("upload" + uploadId);
      toastIds.push(toastId);
      yield put(actions.uploadInitiated(toastId, studyDetail.id, "upload"));
      uploadId = uploadId + 1;
      console.log(file)
    }

    const res = yield call(getStudyPathAndCredentials, {}, obj);

    let i = 0;
    for (let file of files) {
      const params = { ...obj, ...res, file, contentType, folder, toastId: toastIds[i], parentToastId: toastId };
      yield fork(uploadFile, params);
      i = i + 1;
    }

    yield put(actions.studyFileUploadedReset());
    yield put(actions.updateUploadNotification(folder));
    //yield put(actions.finalMessage(toastId, "Succesfully uploaded files. Refreshing page! to", "success"));
    //yield delay(2500);    
    //yield put(actions.getStudyFileFolders());
  } catch (error) {
    // dispatch a failure action to the store with the error
    console.log("error in resp ", error);
    yield put(actions.finalMessage(toastId, "Upload failed!", "error"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  }
}

function* uploadFile(params) {
  //try {
  const api = yield getContext("api");
  const { file, studyId, contentType, folder, toastId, parentToastId } = params;

  const payload = {
    studyId: studyId,
    UploadDownload: folder === "upload" ? "UPLOAD" : "DOWNLOAD",
    folder: contentType,
    fileName: file.name
  };

  const uploadFiledReference = yield call(
    api.post,
    "StudyFile/BeginUpload",
    payload
  );

  const fileResult = yield call(readUploadedFileAsArrayBuffer, file);

  // Generate MD5 hash as a Base64 string
  const wordArray = CryptoJS.lib.WordArray.create(fileResult); // Convert ArrayBuffer to WordArray
  const md5 = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Hex); // Generate MD5 hash in hex
  const digest = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(md5)); // Convert to Base64



  let S3PutObjectParams = {
    Bucket: uploadFiledReference.s3BucketName,
    Key: uploadFiledReference.s3Key,
    Body: file,
    ServerSideEncryption: "AES256",
    ContentType: file.type,

    // ContentEncoding: "aws-chunked",
    ContentMD5: digest
  };
  AWS.config.httpOptions.timeout = 0;
  AWS.config.update({
    accessKeyId: params.accessKeyId,
    secretAccessKey: params.secretAccessKey,
    sessionToken: params.sessionToken
  });
  let s3 = new S3({
    //let s3 = new AWS.S3({
    apiVersion: "2006-03-01",
    region: "eu-west-1",
    sslEnabled: true,
    signatureVersion: "v4"
    // computeChecksums:true
  });

  try {
    // before 10
    const options = {
        /*partSize: 10 * 1024 * 1024, queueSize: 10*/ computeChecksums: true
    };
    // console.log('options ', options);
    //yield call(s3UploadFile, { s3, S3PutObjectParams, options});

    //yield call(s3putFile, { s3, S3PutObjectParams, options});
    yield call(uploadFileToBucket, { s3, S3PutObjectParams, options, toastId: toastId });
    yield put(actions.toastClose(parentToastId));

    yield call(api.put, "StudyFile/EndUpload", {
      md5: digest,
      StudyFileId: uploadFiledReference.studyFileId
    });

    //yield put(actions.updateMessage(toastId, "Uploaded " + __count + " of " + __total + " files."));
    yield put(actions.uploadSuccess(toastId));
    yield delay(2500);
    yield put(actions.toastClose(toastId));

    const state = yield select();
    const { studyDetail } = state.root;

    if (
      studyDetail &&
      studyDetail.id === studyId
    ) {
      yield put(actions.getStudyFileFolders());
    }

    AWS.config.httpOptions.timeout = 120000;
  } catch (error) {
    // dispatch a failure action to the store with the error
    AWS.config.httpOptions.timeout = 120000;
    console.log("error in resp ", error);
    yield put(actions.finalMessage(toastId, "Upload failed!", "error"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  }
  // make channel later for showing percentage progress
  //} catch (error) {
  // dispatch a failure action to the store with the error
  //  console.log("error in resp ", error);
  //  yield put(actions.onApiError());
  //}
}
// only one preview at a time
//  yield fork(uploadFile, params);
function* previewFile(action) {
  try {
    //console.log("previewFile")
    const state = yield select();
    const { file } = action.payload;
    const { studyDetail } = state.root;
    const { fileType } = studyDetail;

    const since = formatIsoDate("");
    const obj = {
      studyId: studyDetail.id,
      UploadDownload: fileType,
      studyFilesSince: since
    };

    //let res = resolveCredentials('download');

    //if (res === null) {


    const res = yield call(getStudyPathAndCredentials, {}, obj);

    if (res.status === 403) {

      const toastId = uniqueId('previewFile');
      yield put(actions.initiateAuthMessage(toastId, "You do not have the correct credentials!", "error"))
      yield delay(2500);
      yield put(actions.toastClose(toastId));
      //console.log(res)
      return
    }
    else if (res && !res.status) {
      AWS.config.update({
        accessKeyId: res.accessKeyId,
        secretAccessKey: res.secretAccessKey,
        sessionToken: res.sessionToken
      });

      //let s3 = new AWS.S3({
      let s3 = new S3({
        apiVersion: "2006-03-01",
        region: "eu-west-1",
        sslEnabled: true,
        signatureVersion: "v4"
      });

      //console.log(file.s3Key)

      let filepngkey = file.s3Key.replace("downloads", "pdfpng")
      filepngkey = filepngkey + ".png"

      const S3GetObjectParams = {
        Bucket: file.s3BucketName,
        //Key: file.s3Key,
        Key: filepngkey,
        Expires: 300
      };

      //Gets signedurl
      const preview = yield call(s3DownloadFile, { s3, S3GetObjectParams });
      yield put(actions.studyPreviewReceive({ thumbnail: preview, fileId: file.id, studyId: studyDetail.id, fileType: fileType }))
    }
    // test on a png
  } catch (error) { }
}
// only one preview at a time
function* previewTxtFile(file) {
  try {
    const state = yield select();
    //const { file } = action.payload;
    const { studyDetail } = state.root;
    const { fileType } = studyDetail;

    const since = formatIsoDate("");
    const obj = {
      studyId: studyDetail.id,
      UploadDownload: fileType,
      studyFilesSince: since
    };

    const res = yield call(getStudyPathAndCredentials, {}, obj);

    if (res.status === 403) {

      const toastId = uniqueId('previewFile');
      yield put(actions.initiateAuthMessage(toastId, "You do not have the correct credentials!", "error"))
      yield delay(2500);
      yield put(actions.toastClose(toastId));
      return ""
    }
    else if (res && !res.status) {
      AWS.config.update({
        accessKeyId: res.accessKeyId,
        secretAccessKey: res.secretAccessKey,
        sessionToken: res.sessionToken
      });

      let s3 = new S3({
        apiVersion: "2006-03-01",
        region: "eu-west-1",
        sslEnabled: true,
        signatureVersion: "v4"
      });

      const S3GetObjectParams = {
        Bucket: file.s3BucketName,
        Key: file.s3Key,
        Expires: 300
      };

      //Gets signedurl
      const preview = yield call(s3DownloadFile, { s3, S3GetObjectParams });
      return preview
    }
  } catch (error) { }
}
// only one preview at a time
//  yield fork(uploadFile, params);
function* getJson(action) {
  try {
    const state = yield select();
    const { file, type } = action.payload;
    const { studyDetail } = state.root;
    const { fileType } = studyDetail;

    const since = formatIsoDate("");
    const obj = {
      studyId: studyDetail.id,
      UploadDownload: fileType,
      studyFilesSince: since
    };

    //let res = resolveCredentials('download');

    //if (res === null) {
    const res = yield call(getStudyPathAndCredentials, {}, obj);

    if (res.status === 403) {

      const toastId = uniqueId('previewFile');
      yield put(actions.initiateAuthMessage(toastId, "You do not have the correct credentials!", "error"))
      yield delay(2500);
      yield put(actions.toastClose(toastId));
      let respEmpty = {}
      yield put(actions.studyConfigJsonReceived(respEmpty))
    }
    else if (res && !res.status) {
      //}

      AWS.config.update({
        accessKeyId: res.accessKeyId,
        secretAccessKey: res.secretAccessKey,
        sessionToken: res.sessionToken
      });
      //let s3 = new AWS.S3({
      let s3 = new S3({
        apiVersion: "2006-03-01",
        region: "eu-west-1",
        sslEnabled: true,
        signatureVersion: "v4"
      });

      let Filekey = file.s3Key

      const S3GetObjectParams = {
        Bucket: file.s3BucketName,
        Key: Filekey,
        Expires: 300
      };

      //Gets signedurl
      const url = yield call(s3DownloadFile, { s3, S3GetObjectParams });
      const resp = yield call(fetchJson, url);
      if (type === "PAYER") {
        yield put(actions.studyPayerJsonReceive(resp))
      }
      else if (type === "CONFIG") {
        yield put(actions.studyConfigJsonReceived(resp))
      }
    }
  } catch (error) { }
}

function* getConfigCSV(file) {

  try {
    const state = yield select();
    const { studyDetail /*, studies*/ } = state.root;
    const { fileType } = studyDetail;

    const since = formatIsoDate("");
    const obj = {
      studyId: studyDetail.id,
      UploadDownload: fileType,
      studyFilesSince: since
    };

    const res = yield call(getStudyPathAndCredentials, {}, obj);

    if (res.status === 403) {

      const toastId = uniqueId('previewFile');
      yield put(actions.initiateAuthMessage(toastId, "You do not have the correct credentials!", "error"))
      yield delay(2500);
      yield put(actions.toastClose(toastId));
      return []
    }
    else if (res && !res.status) {



      AWS.config.update({
        accessKeyId: res.accessKeyId,
        secretAccessKey: res.secretAccessKey,
        sessionToken: res.sessionToken
      });

      let s3 = new S3({
        apiVersion: "2006-03-01",
        region: "eu-west-1",
        sslEnabled: true,
        signatureVersion: "v4"
      });

      let configFilekey = file[0].s3Key

      const S3GetObjectParams = {
        Bucket: file[0].s3BucketName,
        Key: configFilekey,
        Expires: 300
      };

      //Gets signedurl
      const url = yield call(s3DownloadFile, { s3, S3GetObjectParams });
      const resp = yield call(fetchCSV, url);
      var idlist = [];

      //Now we call the collect to make sure they get collected
      let downloadFiles = studyDetail.downloadFiles;
      idlist = resp.map(item => {
        var filename = item.inputPath.split("\\").pop().toUpperCase();
        let downloadItem = downloadFiles.filter((s) => s.name.toUpperCase() === filename)[0];
        if (downloadItem !== undefined) {
          return downloadItem;
        }
        return null;
      })

      return idlist.filter(p => p !== null)
    }
  } catch (error) {
    return undefined;
  }
}

async function fetchCSV(url) {
  let resp;
  try {
    let data = await fetch(url);
    resp = await data.text();
    var allTextLines = resp.split(/\r\n|\n/);
    var lines = []
    for (var i = 0; i < allTextLines.length; i++) {
      var dataline = allTextLines[i].split(',');
      if (i > 0) {
        var item = { "inputPath": dataline[0], "orientation": dataline[1] }
        lines.push(item);
      }
    }
  }
  catch (e) {
    resp = e.message;
  }
  return lines;
}
async function fetchJson(url) {
  let resp;
  try {
    let data = await fetch(url);
    resp = await data.json();
  }
  catch (e) {
    resp = e.message;
  }
  return resp;
}
async function fetchText(url) {
  let resp;
  try {
    let data = await fetch(url);
    resp = await data.text();
  }
  catch (e) {
    resp = e.message;
  }
  return resp;
}

const resolveTypePayload = folder => {
  if (folder.toLowerCase() === "upload") {
    return folder;
  }
  return "SASUPLOAD";
};

function* assignStudyPermissions(action) {
  let originalPerimissions = [];
  try {
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    originalPerimissions = studyDetail.dashboardPermissions;

    const params = {
      AssignToUserId: studyDetail.recipientUserId,
      StudyId: studyDetail.id,
      permissions: studyDetail.dashboardPermissions
    };

    yield call(api.post, "StudyPermission/AssignStudyPermissions", params);
    yield put(
      actions.studyPermissionsChanged(studyDetail.dashboardPermissions, studyDetail.id)
    );
  } catch (error) {
    const oldState = yield select();
    const studyId = oldState.studyDetail ? oldState.studyDetail.id : undefined;
    yield put(actions.studyPermissionsChanged(originalPerimissions, studyId));
    const toastId = uniqueId("assignStudyPermissions");

    yield put(actions.finalMessage(toastId, "Assign permissions failed!", "error"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  }
}

function* getStudyPermissions(action) {
  try {
    const api = yield getContext("api");
    const { id, customerId } = action.payload;
    const res = yield call(
      api.get,
      `StudyPermission/AssignStudyPermissions/${id}/${customerId}`
    );
    yield put(actions.studyPermissionsReceived(res));
  } catch (error) {
    console.log("error in resp ", error);
    yield put(actions.onApiError());
  }
}
function* getSasJobsSaga(action) {
  try {
    const { studyId } = action.payload;
    const api = yield getContext("api");
    const res = yield call(api.get, `sas/List/${studyId}`);
    yield put(actions.sasJobsReceived(res ? res : []));
  } catch (error) {
    console.log("error in resp ", error);
  }
}


export function* getSasJobsSagaPaging(action) {
  const { folders } = action.payload;
  console.log("getSasJobsSagaPaging", folders)
  yield all(folders.map(folder => call(getSasJob, folder)));
}

function* getSasJob(folder) {
  try {

    const state = yield select();
    const { studyDetail } = state.root;
    const api = yield getContext("api");

    const qc_folder_exists = studyDetail.sasFolders.filter(item => item.folder.toUpperCase() === folder.toUpperCase() + "/QC")[0]

    yield put(actions.sasJobsResetPaging(folder));

    if (qc_folder_exists) {
      yield put(actions.sasJobsResetPaging(folder + "/qc"));
    }

    let res = [], i = 1;

    do {

      let obj = {
        "studyId": studyDetail.id,
        "folder": folder,
        "Page": i,
        "ResultsPrPage": 600
      };

      res = yield call(api.post, "sas/List", obj);
      yield put(actions.sasJobsReceivedPaging(res ? res : []));
      i++
    }

    while (res.length >= 600)

    if (qc_folder_exists) {
      let res = [], j = 1;

      do {

        let obj = {
          "studyId": studyDetail.id,
          "folder": folder + "/qc",
          "Page": j,
          "ResultsPrPage": 600
        };

        res = yield call(api.post, "sas/List", obj);
        yield put(actions.sasJobsReceivedPaging(res ? res : []));
        j++
      }
      while (res.length >= 600)
    }
    yield put(actions.sasJobsAddQCStatus());
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* changeStudyFileStatus(action) {
  try {
    const { id, value } = action.payload;
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;

    const params = {
      StudyId: studyDetail.id,
      ReviewStatus: value === "sponsor final" ? "sponsorFinal" : value,
      StudyFileIds: [id]
    };

    yield call(api.put, "StudyFile/UpdateStudyFileStatus/", params);
    yield put(actions.studyFileStatusChanged(id, value));

  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* changeStudyFileType(action) {
  try {
    const { id, value } = action.payload;
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    const params = {
      StudyFileType: value,
      StudyFileId: id
    };
    yield call(api.put, "StudyFile/UpdateStudyFileType/", params);
    const { /*selectedDate,*/ fileType } = studyDetail;

    yield put(actions.studyFileResetPaging(fileType));
    let res = [], i = 0;

    do {

      let obj = {
        "studyId": studyDetail.id,
        "type": fileType,
        "folder": studyDetail.selectedUploadDownloadfolder,
        "studyFilesSince": /*formatIsoDate(new Date())*/ new Date().toISOString(),
        "Page": i,
        "ResultsPrPage": 600
      };
      res = yield call(api.post, "Study/GetStudyFilesByType", obj);
      yield put(actions.studyUploadsReceivedPaging(res.studyfiles || [], fileType));
      i++
    }
    while (res.studyfiles.length >= 600)
    yield put(actions.getStudyUploadsFinished())


    //const res = yield call(api.post, "Study/GetStudyFilesByType", obj);
    //yield put(actions.studyUploadsReceived(res.studyfiles || [],fileType));
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* collectMultipleFiles(action) {
  const toastId = uniqueId('CollectMultipleFiles');

  try {
    const state = yield select();
    const { id } = action.payload;
    const { studyDetail, studies } = state.root;
    const { downloadFiles, studyName } = studyDetail;
    const api = yield getContext("api");
    //let isCsvCollect = false
    var selectedFiles = downloadFiles.filter(item => item.checkBox);
    var txtFiles = selectedFiles.filter(item => item.name.toUpperCase().endsWith('.TXT'))
    //var csvFile = selectedFiles.filter(item => item.name.toUpperCase().endsWith('.CSV'))

    //Both a csv file and at least one text file is selected 
    //if (csvFile.length > 0 && txtFiles.length > 0) {
    //  yield put(actions.initiateMessage(toastId, studyDetail.id, 'Only select text file(s) or a single csv file not both.'));
    //  yield delay(2500);
    //  yield put(actions.toastClose(toastId));
    //  return
    //}

    yield put(actions.initiateMessage(toastId, studyDetail.id, 'Starting collection...'));

    //A csvfile is selected - fetch file and use contents as the files neededing collection
    //if (csvFile.length === 1 && txtFiles.length === 0) {
    //  txtFiles = yield call(getConfigCSV, csvFile);
    //  isCsvCollect = true
    //}

    //console.log(txtFiles)
    //if (!txtFiles) {
    //  yield put(actions.finalMessage(toastId, "Could not fetch csv file!", "error"));
    //  yield delay(2500);
    //  yield put(actions.toastClose(toastId));
    //  return
    //}

    var idlist = id || txtFiles.map(item => item.id);

    const selectedStudy = studies.filter(p => p.studyId === studyDetail.id)[0];
    const uploadPerm = selectedStudy.permissions.filter(p => p.permissionId === "UPLOAD");
    const downloadPerm = selectedStudy.permissions.filter(p => p.permissionId === "DOWNLOAD");
    yield put(actions.updateMessage(toastId, "Collecting " + idlist.length + " files. "));

    if (uploadPerm.length !== 1 || downloadPerm.length !== 1) {
      yield put(actions.finalMessage(toastId, "Insufficient permissions to collect files.!", "error"));
      yield delay(2500);
      yield put(actions.toastClose(toastId));
      return
    }

    let obj = {
      "UtilId": "AwsWord",
      "UtilPermissions": [{ "StudyId": studyDetail.id.toString(), "Permission": uploadPerm[0].permissionId }, { "StudyId": studyDetail.id.toString(), "Permission": downloadPerm[0].permissionId }],
      "sync": false,
      "Payload": {
        "studyId": studyDetail.id.toString(),
        "studyName": studyName,
        "destination": /*(!isCsvCollect) ?*/ "Collected_" + studyDetail.id.toString() /*: csvFile[0].name.split('.').slice(0, -1).join('.')*/,
        "studyfileIds": idlist,
        "sponsorGuid": selectedStudy.sponsorGuid,
        "studyGuid": selectedStudy.studyGuid
      }
    }

    const res = yield call(api.post, "Utils/initiateUtilLambda", obj);

    if (res === "Accepted") {
      yield put(actions.finalMessage(toastId, "Collection successfull. Document will be availeble in uploads.", "success"));
    }
    else {
      yield put(actions.finalMessage(toastId, "Collection failed", "warning"));
    }
    yield delay(2000);
    yield put(actions.toastClose(toastId));
  }
  catch (error) {

    yield put(actions.finalMessage(toastId, "Collection failed", "error"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
    console.log("error in resp ", error);
  }
}



function* MigrateSasFiles(action) {
  const toastId = uniqueId('MigrateSasFiles');

  try {
    const state = yield select();
    const { studyDetail, studies } = state.root;
    const { sasjobsFiles } = studyDetail;

    const api = yield getContext("api");
    //let isCsvCollect = false
    var selectedFiles = sasjobsFiles.filter(item => item.checkBox)

    const data_tfl = selectedFiles[0].folder.toLowerCase().includes("adam") ? "data" : "tfl";

    const idlist = selectedFiles.map(item => item.id)

    yield put(actions.initiateMessage(toastId, studyDetail.id, 'Starting migration...'));

    const selectedStudy = studies.filter(p => p.studyId === studyDetail.id)[0];
    const uploadPerm = selectedStudy.permissions.filter(p => p.permissionId === "UPLOAD");

    yield put(actions.updateMessage(toastId, "Migrating " + idlist.length + " files. "));

    if (uploadPerm.length !== 1) {
      yield put(actions.finalMessage(toastId, "Insufficient permissions to migrate files.!", "error"));
      yield delay(2500);
      yield put(actions.toastClose(toastId));
      return
    }

    let obj = {
      "UtilId": "sasMigration",
      "UtilPermissions": [{ "StudyId": studyDetail.id.toString(), "Permission": uploadPerm[0].permissionId }],
      "sync": false,
      "Payload": {
        "studyId": studyDetail.id,
        "destination": "migration",
        "sasjobs2migrate": idlist,
        "sponsorGuid": selectedStudy.sponsorGuid,
        "studyGuid": selectedStudy.studyGuid,
        "data_tfl": data_tfl,
        "sasreplace": [{ "key": "__test__", "value": "__test__" }],
        "jobinreplace": [{ "key": "__test__", "value": "__test__" }],
        "joboutreplace": [{ "key": "__test__", "value": "__test__" }],
        "parentFolder": (data_tfl === "tfl") ? "tfl" : "adam"
      }
    }

    const res = yield call(api.post, "Utils/initiateUtilLambda", obj);

    if (res === "Accepted") {
      yield put(actions.finalMessage(toastId, "Migration successfull. Zip archive will be availeble in uploads.", "success"));
    }
    else {
      yield put(actions.finalMessage(toastId, "Migration failed", "warning"));
    }
    yield delay(2000);
    yield put(actions.toastClose(toastId));
  }
  catch (error) {
    yield put(actions.finalMessage(toastId, "Migration failed", "error"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
    console.log("error in resp ", error);
  }
}

function* getStudyPermissionsForDashboard(action) {
  try {
    const api = yield getContext("api");
    const state = yield select();
    const { root } = state;
    const { userId } = root;
    const { studyId } = action.payload;

    const res = yield call(
      api.get,
      `StudyPermission/AssignStudyPermissions/${studyId}/${userId}`
    );

    yield put(
      actions.studyPermissionsForDashboardReceived(
        res.permissions,
        studyId
      )
    );
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* getIssuesForDashboard(action) {
  try {

    const api = yield getContext("api");
    const state = yield select();
    const { studyId } = action.payload;

    const res = yield call(
      api.get,
      `folder/get/${studyId}`
    );

    yield put(
      actions.issuesForDashboardReceived(
        res,
        studyId
      )
    );
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* ResetStudyFileRO(action) {
  try {

    const toastId = uniqueId('ResetStudyFileRO');
    const { id } = action.payload;
    const api = yield getContext("api");
    const apiURL = "studyfile/RO/" + id.toString();

    //Starting toast
    yield put(actions.initiateMessage(toastId, id, 'Start reset of studyFileRO'));

    const res = yield call(api.put, apiURL);
    console.log(res)

    //Finalizing toast
    if (res) {
      yield put(actions.finalMessage(toastId, "Succesfully reset " + res + " records", "success"));
    }
    else {
      yield put(actions.finalMessage(toastId, "Reset failed!", "warning"));
    }

    //Closing toast
    yield delay(2500);
    yield put(actions.toastClose(toastId));

  } catch (error) {
    console.log("error in resp ", error);
  }
}
function* ResetSasJobRO(action) {
  try {

    const toastId = uniqueId('ResetSasJobRO');
    const { id } = action.payload;
    const api = yield getContext("api");
    const apiURL = "sas/RO/" + id.toString();

    //Starting toast
    yield put(actions.initiateMessage(toastId, id, 'Start reset of sasJobRO'));

    const res = yield call(api.put, apiURL);
    console.log(res)

    //Finalizing toast
    if (res) {
      yield put(actions.finalMessage(toastId, "Succesfully reset " + res + " records", "success"));
    }
    else {
      yield put(actions.finalMessage(toastId, "Reset failed!", "warning"));
    }

    //Closing toast
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  } catch (error) {
    console.log("error in resp ", error);
  }
}


function* RenameFolder(id, path) {
  try {
    const api = yield getContext("api");
    const apiURL = "Folder/rename/" + id.toString() + "/" + path;
    const res = yield call(api.put, apiURL);
    return res
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* correctIssueFromDashboard(action) {
  try {
    const { key } = action.payload;
    const api = yield getContext("api");
    const state = yield select();
    const { root } = state;
    const { studyDetail } = root;
    const { id, dashboardIssues } = studyDetail;
    const issue = dashboardIssues.filter(item => item.folder === key)

    if (issue.length === 0) {
      return
    }

    yield put(actions.startCorrectingIssues())
    let i = 0
    for (let path of issue[0].corrected) {
      const toastId = uniqueId('issue' + i);
      yield put(actions.initiateMessage(toastId, id, 'Renaming folder: ' + path));
      let resp = yield call(RenameFolder, id, encodeURIComponent(path));
      if (resp) {
        yield put(actions.finalMessage(toastId, "Succesfully renamed " + path + " entries", "success"));
      }
      else {
        yield put(actions.finalMessage(toastId, "Rename failed!", "warning"));
      }
      yield delay(150)
      yield put(actions.toastClose(toastId));
      i = i + 1
    }
    yield put(actions.finishCorrectingIssues())
    yield put(actions.getIssuesForDashboard(id))
    yield put(actions.issueToggleModalEmpty())
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* accessLogExtract2Db(action) {
  try {
    const { s3key } = action.payload;
    const { id } = action.payload;
    const api = yield getContext("api");
    const res = yield call(api.put, `Admin/AccessLogExtract2Db`, {
      s3Key: s3key,
      studyId: id
    });

    yield put(actions.accessLogExtract2DbReceived(res, id));
  } catch (error) {
    console.log("error in resp ", error);
  }
}
function* accessLogPartitionUpdate(action) {
  try {
    const { id } = action.payload;
    const api = yield getContext("api");
    const res = yield call(api.put, `Admin/AccessLogPartitionUpdate/${id}`);

    yield put(actions.accessLogPartitionUpdateReceived(res, id));
  } catch (error) {
    console.log("error in resp ", error);
  }
}
function* accessLogExtract(action) {
  try {
    console.log(action.payload);
    const { id } = action.payload;
    const { year } = action.payload;
    const { month } = action.payload;
    const api = yield getContext("api");
    const res = yield call(api.get, `Admin/AccessLogExtract/${id}/${year}/${month ? month : ""}`);
    yield put(actions.accessLogExtractReceived(res));
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* adminEnableAccessLog(action) {
  try {
    const { id } = action.payload;
    const api = yield getContext("api");
    const res = yield call(api.post, `Admin/AdminEnableAccessLog/${id}`);
    yield put(actions.adminEnableAccessLogReceived(res, id))
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* accessLogInfo(action) {
  try {
    const { id } = action.payload;
    const api = yield getContext("api");
    const res = yield call(api.get, `Admin/AccessLogInfo/${id}`);
    yield put(actions.accessLogInfoReceived(res, id));
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* listAccessLogExtracts(action) {
  try {
    const { id } = action.payload;
    const api = yield getContext("api");
    const res = yield call(api.get, `Admin/ListAccessLogExtracts/${id}`);
    yield put(actions.listAccessLogExtractsReceived(res, id));
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* getSasIssues(action) {
  try {

    const api = yield getContext("api");
    const state = yield select();
    const { studyId } = action.payload;

    let res = []
    const sort = "DESCENDING"

    let url = `Report/GetJobs?studyId=${studyId}&runstatus=ERROR&sort=${sort}&pageNumber=1&pageSize=20`
    res = yield call(api.get, url);
    yield put(
      actions.sasIssuesReceived(
        res.data,
        studyId
      )
    );
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* requestStudyPermissionManager(action) {
  try {
    const { permission } = action.payload;
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    yield call(
      api.get,
      `StudyPermission/RequestStudyPermission/${studyDetail.id
      }/${permission}`
    );
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* getLatestUpdateDate(action) {
  try {
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    const { fileType } = action.payload;
    const urlParam = (fileType.toUpperCase() !== "RELEASE") ? fileType : "download"
    const res = yield call(api.get, "Studyfile/LastUpdate/" + studyDetail.id + "/" + urlParam);
    yield put(actions.latestUpdateDateReceived(res, fileType));
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}

function* getLatestSasUpdateDate(action) {
  try {
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    let res;
    if (studyDetail.sasJobs.length === 0) {
      res = ""
    }

    else if (studyDetail.sasjobsfolder) {
      res = yield call(api.get, "sas/LastUpdate/" + studyDetail.id + "/" + encodeURIComponent(studyDetail.sasjobsfolder));
    }

    else {
      res = yield call(api.get, "sas/LastUpdate/" + studyDetail.id);
    }

    yield put(actions.latestSasUpdateDateReceived(res));
  }
  catch (error) {
    console.log("error in resp ", error);
  }
}

function* initialize(action) {
  try {
    const api = yield getContext("api");
    const { studyId, studyFileId } = action.payload;
    const state = yield select();
    const { studyDetail } = state.root;
    const { downloadFiles, uploadedFiles, fileType } = studyDetail;

    let studyFile;
    // todo: write a spec for this
    if (fileType === "download") {
      studyFile = downloadFiles.filter(sf => sf.id === studyFileId)[0];
    }
    else if (fileType === "upload") {
      studyFile = uploadedFiles.filter(sf => sf.id === studyFileId)[0];
    }

    const studyFileRes = yield call(api.get, `StudyFile/GetStudyFileVersions/${studyFileId}`);
    //console.log(studyFileRes)
    const url = yield call(previewTxtFile, studyFile);

    if (url === "") {
      yield put(actions.studyFilePlaceInitialJob(
        studyId,
        studyFile,
        studyFileRes,
        "You do not have access to view the file!"));
    }
    else {

      const content = yield call(fetchText, url);
      //console.log(content)
      yield put(actions.studyFilePlaceInitialJob(
        studyId,
        studyFile,
        studyFileRes,
        content));
    }
  } catch (error) { }
}

function* initializeV2(action) {
  try {
    const api = yield getContext("api");
    const { studyId, studyFileId } = action.payload;
    const state = yield select();
    const { studyDetail } = state.root;
    const { fileType } = studyDetail;

    const uploadDownload = (fileType !== "upload") ? "DOWNLOAD" : fileType.toUpperCase()

    const studyFile = yield call(api.get, `StudyFile/GetStudyFile/${studyFileId}`);
    const latest = yield call(api.get, `StudyFile/GetLatestStudyFile/${studyId}/${encodeURIComponent(studyFile.folder)}/${studyFile.fileName}/${uploadDownload}`);
    const studyFileRes = yield call(api.get, `StudyFile/GetStudyFileVersions/${studyFileId}`);
    const url = yield call(previewTxtFile, studyFile);

    if (url === "") {
      yield put(actions.studyFilePlaceInitialJob(
        studyId,
        studyFile,
        studyFileRes,
        "You do not have access to view the file!",
        "TXT",
        ""))
    }

    else {

      //Find out if it is a txt, png or pdf file
      if (studyFile.fileName.match("^.+\.txt$|^.+\.TXT$") != null) {
        const content = yield call(fetchText, url);
        //console.log(content)
        yield put(actions.studyFilePlaceInitialJob(
          studyId,
          studyFile,
          studyFileRes,
          content,
          "TXT",
          (latest.studyFileId === studyFile.studyFileId) ? "" : latest.studyFileId));
      }
      else if (studyFile.fileName.match("^.+\.png$|^.+\.PNG$") != null) {
        yield put(actions.studyFilePlaceInitialJob(
          studyId,
          studyFile,
          studyFileRes,
          url,
          "PNG",
          (latest.studyFileId === studyFile.studyFileId) ? "" : latest.studyFileId));
      }

      else if (studyFile.fileName.match("^.+\.pdf$|^.+\.PDF$") != null) {
        yield put(actions.studyFilePlaceInitialJob(
          studyId,
          studyFile,
          studyFileRes,
          url,
          "PDF",
          (latest.studyFileId === studyFile.studyFileId) ? "" : latest.studyFileId));
      }
      else if (studyFile.fileName.match("^.+\.sas$|^.+\.SAS$") != null) {
        const content = yield call(fetchText, url);
        //console.log(content)
        yield put(actions.studyFilePlaceInitialJob(
          studyId,
          studyFile,
          studyFileRes,
          content,
          "SAS",
          (latest.studyFileId === studyFile.studyFileId) ? "" : latest.studyFileId));
      }
      else {
        const since = formatIsoDate("");
        const obj = {
          studyId: studyDetail.id,
          UploadDownload: fileType.trim(),
          studyFilesSince: since
        };

        const res = yield call(getStudyPathAndCredentials, {}, obj);

        AWS.config.httpOptions.timeout = 0;
        AWS.config.update({
          accessKeyId: res.accessKeyId,
          secretAccessKey: res.secretAccessKey,
          sessionToken: res.sessionToken
        });
        //let s3 = new AWS.S3({
        let s3 = new S3({
          apiVersion: "2006-03-01",
          region: "eu-west-1",
          sslEnabled: true,
          signatureVersion: "v4"
        });

        let S3GetObjectParams = {
          Bucket: latest.s3BucketName,
          Key: latest.s3Key,
          Expires: 600,
          ResponseContentDisposition: 'attachment; filename ="' + latest.fileName + '"'
        };

        const dwnloadUrl = yield call(s3DownloadFile, { s3, S3GetObjectParams });

        const a = document.createElement('a')
        a.href = dwnloadUrl
        a.download = dwnloadUrl.split('/').pop()
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        AWS.config.httpOptions.timeout = 120000;
        yield put(actions.studyFilePlaceInitialJob(
          studyId,
          studyFile,
          studyFileRes,
          "We do not yet support viewing this type of file. File has been sent to you via download!",
          "TXT",
          ""))
      }
    }
  } catch (error) { }
}



function* compareFile(action) {
  try {
    const { studyId, studyFileId } = action.payload;
    //console.log(studyId, studyFileId)
    const state = yield select();
    const { studyDetail } = state.root;
    const { fileVersions } = studyDetail;
    const studyFile = fileVersions.filter(sf => sf.id === studyFileId)[0];

    const url = yield call(previewTxtFile, studyFile);

    if (url === "") {
      yield put(actions.studyFilePlaceComparerJob(studyId, studyFile, "You do not have access to view the file!"));
    }
    else {
      const content = yield call(fetchText, url);
      yield put(actions.studyFilePlaceComparerJob(studyId, studyFile, content));
    }
  } catch (error) { }
}

function* getStudy(action) {
  try {
    const api = yield getContext("api");
    const { studyId, viewMode } = action.payload;
    const state = yield select();
    const { studies, userId } = state.root;
    //const exists = studies.filter(x => x.studyId === parseInt(studyId, 0)).length > 0
    const exists = studies.some(x => x.studyId === parseInt(studyId, 10));
    if (exists) {
      const [study, res] = yield all([
        call(api.get, `Study/get/${studyId}`),
        call(api.get, `StudyPermission/AssignStudyPermissions/${studyId}/${userId}`)
      ]);
      //const study = yield call(api.get, `Study/get/${studyId}`);
      //const permissions = yield call(api.get, `StudyPermission/AssignStudyPermissions/${studyId}/${userId}`);      
      if (study && !study.status) {
        yield put(actions.receivedStudy(study, viewMode))
        yield put(actions.studyPermissionsForDashboardReceived(res.permissions, studyId))
        //yield put(actions.getStudyPermissionsForDashboard(studyId))
        yield put(actions.getAdminsOnStudy(studyId))
        if (study && study.permissions && study.permissions.filter(perm => perm.permissionId === "UPLOAD").length > 0) {
          yield put(actions.getIssuesForDashboard(studyId))
        }
        if (study && study.permissions && study.permissions.filter(perm => perm.permissionId === "SASUPLOAD").length > 0) {
          yield put(actions.getSasIssues(studyId))
        }
      }
    }
  }
  catch (error) {
    console.error('Failed to fetch study:', error);
  }
}

function* sasfilesChangedAfterDate(action) {
  //console.log("sasfilesChangedAfterDate")
  try {
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    const { sasReceivedDate } = studyDetail;

    const path = `Sas/ListAfterDate/${studyDetail.id}/${sasReceivedDate}`
    const res = yield call(api.get, path);
    //console.log(path, res)
    yield put(actions.sasfilesChangedAfterDateFinished(res))
  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* filesChangedAfterDate(action) {
  try {
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    const { fileType, uploadReceivedDate, downloadReceivedDate, releaseReceivedDate } = studyDetail;

    //yield put(actions.emptyStudyFileFolders(fileType))
    let date;
    if (fileType.toUpperCase() === "RELEASE") {
      date = releaseReceivedDate
    }
    else if (fileType.toUpperCase() === "DOWNLOAD") {
      date = downloadReceivedDate
    }
    else if (fileType.toUpperCase() === "UPLOAD") {
      date = uploadReceivedDate
    }
    //if (fileType.toUpperCase() === "DOWNLOAD" || fileType.toUpperCase() === "UPLOAD") {
    let params = {
      studyId: studyDetail.id,
      UploadDownload: (fileType.toUpperCase() !== "RELEASE") ? fileType : "download",
      studyFilesSince: date,
      release: (fileType.toUpperCase() === "RELEASE") ? true : false
    };

    const res = yield call(api.post, "Study/GetStudyFilesAfterDate", params);
    yield put(actions.filesChangedAfterDateFinished(res, fileType))
  } catch (error) {
    console.log("error in resp ", error);
  }
}


function* refreshEveryXminutes(action) {
  try {
    //console.log("refreshEveryXminutes called")
    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;

    if (!studyDetail) {
      console.log("nothing to refresh")
      return
    }

    const { fileType, uploadReceivedDate, downloadReceivedDate, releaseReceivedDate, sasReceivedDate } = studyDetail;

    if (!uploadReceivedDate && !downloadReceivedDate && !releaseReceivedDate && !sasReceivedDate) {
      console.log("nothing to refresh")
      return
    }

    //yield put(actions.emptyStudyFileFolders(fileType))
    let date;
    if (fileType.toUpperCase() === "RELEASE" && releaseReceivedDate) {
      date = releaseReceivedDate
    }
    else if (fileType.toUpperCase() === "DOWNLOAD" && downloadReceivedDate) {
      date = downloadReceivedDate
    }
    else if (fileType.toUpperCase() === "UPLOAD" && uploadReceivedDate) {
      date = uploadReceivedDate
    }
    else if (fileType.toUpperCase() === "SASJOBS" && sasReceivedDate) {
      date = sasReceivedDate
    }

    if (!date) {
      console.log("nothing to refresh")
      return
    }

    const std = yield call(getStudiesSagaV2);
    //check that data has been collected (date) later than changed on server (std date)
    const server = std.filter(t => t.studyId === studyDetail.id && t.lastModifiedDate > date)

    if (server.length === 1) {

      if (fileType.toUpperCase() !== "SASJOBS") {

        let params = {
          studyId: studyDetail.id,
          UploadDownload: (fileType.toUpperCase() !== "RELEASE") ? fileType : "download",
          studyFilesSince: date,
          release: (fileType.toUpperCase() === "RELEASE") ? true : false
        };
        const res = yield call(api.post, "Study/GetStudyFilesAfterDate", params);
        yield put(actions.filesChangedAfterDateFinished(res, fileType))
      }

      else {

        const path = `Sas/ListAfterDate/${studyDetail.id}/${sasReceivedDate}`
        const res = yield call(api.get, path);
        yield put(actions.sasfilesChangedAfterDateFinished(res))
      }
    }
    else {
      //const server2 = std.filter(t => t.studyId === studyDetail.id && t.lastModifiedDate <= date)
      //console.log("study already up to date", server2, studyDetail)
    }

  } catch (error) {
    console.log("error in resp ", error);
  }
}



function* deleteAllFileVersions(action) {
  const toastId = uniqueId('deleteAllFileVersions');

  try {
    const state = yield select();
    const { id } = action.payload;
    const { studyDetail } = state.root;
    const { uploadFiles, downloadFiles, fileType } = studyDetail;
    const studyId = studyDetail.id
    const api = yield getContext("api");

    var idlist
    // todo: write a spec fo this
    if (fileType === "download") {
      idlist = id || downloadFiles.filter(item => item.checkBox);
    }
    else if (fileType === "upload") {
      idlist = id || uploadFiles.filter(item => item.checkBox);
    }

    //console.log(idlist)

    //Starting toast     
    yield put(actions.initiateMessage(toastId, studyDetail.id, 'Starting to delete' + idlist[0].fileName + "."));

    //Updating toast with information
    const fileId = yield call(api.put, `StudyFile/DeleteStudyFileVersions/${studyId}/${encodeURIComponent(idlist[0].folder)}/${idlist[0].fileName}`);
    let __count = 0
    if (fileId) {
      __count++;
      yield put(actions.updateMessage(toastId, "Deleting " + idlist[0].name + " files."));
    }

    //Finalizing toast
    if (__count === idlist.length) {
      yield put(actions.finalMessage(toastId, "Succesfully deleted file. Refreshing page!", "success"));
    }
    else {
      yield put(actions.finalMessage(toastId, "Deleted " + __count + " of " + idlist.length + " files. Refreshing page!", "warning"));
    }

    //Closing toast
    yield delay(2500);
    yield put(actions.toastClose(toastId));
    yield put(actions.getStudyFileFolders())
  }
  catch (error) {
    console.log("error in resp ", error);
    yield put(actions.finalMessage(toastId, "Delete failed!", "error"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  }
}

function* downloadSasFileV2(action) {
  const toastId = uniqueId('downloadSasFileV2');
  const api = yield getContext("api");
  const state = yield select();
  const { id } = action.payload;
  const { studyDetail } = state.root;
  const { downloadFiles } = studyDetail;

  const studyFile = downloadFiles.filter(item => item.checkBox)[0];

  yield put(actions.initiateMessage(toastId, id, 'Starting to download sas file.'));

  try {
    const job = yield call(api.get, `sas/ReadJob/${studyFile.sasJobId}`);
    const filepath = job.folder + "/" + job.fileName
    const lastModified = new Date(job.sasJobCreatedDate)
    let sasfile = new File([job.sasCode], filepath, { type: "", lastModified: lastModified })

    var a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(sasfile);
    a.download = job.fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    yield put(actions.finalMessage(toastId, "Succesfully downloaded files.", "success"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  } catch (error) {
    console.log("error in resp ", error);
    yield put(actions.finalMessage(toastId, "Download failed!", "error"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  }
}

const tryGuessFolder = (res, name, selectedUploadDownloadfolder) => {

  //Initialy set it to selectedUploadDownloadfolder otherwise missing
  let folder = ""

  //Third priority guess the folder
  if (name.toUpperCase().endsWith(".SAS7BDAT") || name.toUpperCase().endsWith(".XLS") || name.toUpperCase().endsWith(".XLSX") ||
    name.toUpperCase().endsWith(".CSV") || name.toUpperCase().endsWith(".XPT") || name.toUpperCase().endsWith(".SAS7BNDX")) {
    folder = "data"

    if (name.toUpperCase().endsWith(".SAS7BDAT") && name.toUpperCase().startsWith("AD")) {
      folder = "data/adam"
    }

    if ((name.toUpperCase().endsWith(".SAS7BDAT") && name.toUpperCase().startsWith("RELREC")) ||
      (name.toUpperCase().endsWith(".SAS7BDAT") && name.toUpperCase().startsWith("SUPP")) ||
      (name.toUpperCase().endsWith(".SAS7BDAT") && name.toUpperCase().split(".")[0].length === 2)) {
      folder = "data/sdtm"
    }
  }
  else {
    folder = "document"
  }

  //second priority set it to the folder from the server
  if (res && res.length > 0) {
    if (res[0].fileName.toUpperCase() === name.toUpperCase()) {
      folder = res[0].folder
    }
  }

  //first priority set it to selectedUploadDownloadfolder
  folder = (selectedUploadDownloadfolder && selectedUploadDownloadfolder.length > 0) ? selectedUploadDownloadfolder[0] : folder

  return folder
}

export function* updatePotentialUploads(action) {
  const { files } = action.payload

  try {
    //First update Potential uploads
    yield put(actions.storePotentialUploads(files))

    //The go through them 10 at a time to check their status
    const limit = 10
    const chunked = chunked_array(files, limit)

    for (let items of chunked) {
      yield all(items.map(file => call(uploadExistsOnServer, file)));
      yield delay(200)
    }

  } catch (error) {
    console.log("error in resp ", error);
  }
}

function* uploadExistsOnServer(file) {

  try {
    const fileResult = yield call(readUploadedFileAsArrayBuffer, file);
    // Generate MD5 hash as a Base64 string
    const wordArray = CryptoJS.lib.WordArray.create(fileResult); // Convert ArrayBuffer to WordArray
    const md5 = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Hex); // Generate MD5 hash in hex
    const digest = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(md5)); // Convert to Base64

    const api = yield getContext("api");
    const state = yield select();
    const { studyDetail } = state.root;
    const { selectedUploadDownloadfolder } = studyDetail
    const searchQuery = file.path

    const uploadDownload = "upload"
    const sort = "DESCENDING"

    let url = `Report/GetFiles?studyId=${studyDetail.id}&uploadDownload=${uploadDownload}`
    url = url.concat(`&searchQuery=${encodeURIComponent(searchQuery)}`)
    url = url.concat(`&sort=${sort}&pageNumber=1&pageSize=15`)
    let res = yield call(api.get, url);
    //let folder = tryGuessFolder(res.data, file.path, selectedUploadDownloadfolder)
    let type = "upload"

    let uploadFileEntry = {}

    //Something came back from the search on filename from the server
    if (res.data.length > 0) {

      //Folder is selected: compare folder, filename, and md5
      if (selectedUploadDownloadfolder && selectedUploadDownloadfolder.length > 0) {
        const simple = res.data.filter(item => item.folder === selectedUploadDownloadfolder[0] && item.fileName.toLowerCase() === file.path.toLowerCase())

        //There exists one with the same folder and filename
        if (simple.length > 0) {

          //md5 hash same as on server
          if (simple[0].mD5 === digest) {
            uploadFileEntry = { id: file.path, name: file.path, type: type, folder: selectedUploadDownloadfolder[0], new: false, existing: true, changed: false, status: "Same as on server", md5: digest, loading: "NOT STARTED" }
          }

          //md5 hash not the same update to server version
          else {
            uploadFileEntry = { id: file.path, name: file.path, type: type, folder: selectedUploadDownloadfolder[0], new: false, existing: true, changed: true, status: "Update to server version", md5: digest, loading: "NOT STARTED" }
          }
        }

        //No file with same folder and filename exists on server
        else {
          uploadFileEntry = { id: file.path, name: file.path, type: type, folder: selectedUploadDownloadfolder[0], new: true, existing: false, changed: true, status: "New file. Not on server", md5: digest, loading: "NOT STARTED" }
        }
      }
    }
    //Nothing on server with the same filename
    else {
      uploadFileEntry = { id: file.path, name: file.path, type: type, folder: selectedUploadDownloadfolder[0], new: true, existing: false, changed: true, status: "New file. Not on server", md5: digest, loading: "NOT STARTED" }
    }
    yield put(actions.uploadFileStatusDetermined(uploadFileEntry))
  } catch (error) {
    console.log("error in resp ", error);
  }
}

export function* getStudyAccessReport() {
  const toastId = uniqueId('getAccessReport');

  try {
    const state = yield select();
    const { studyDetail, studies } = state.root;
    //const { downloadFiles, studyName } = studyDetail;
    const api = yield getContext("api");
    yield put(actions.initiateMessage(toastId, studyDetail.id, 'Starting generating report...'));

    const selectedStudy = studies.filter(p => p.studyId === studyDetail.id)[0];
    const uploadPerm = selectedStudy.permissions.filter(p => p.permissionId === "UPLOAD");

    if (uploadPerm.length !== 1) {
      yield put(actions.finalMessage(toastId, "Insufficient permissions to collect files.!", "error"));
      yield delay(2500);
      yield put(actions.toastClose(toastId));
      return
    }

    let obj = {
      "UtilId": "accessreport",
      "UtilPermissions": [{ "StudyId": studyDetail.id.toString(), "Permission": uploadPerm[0].permissionId }],
      "sync": false,
      "Payload": {
        "userId": "not used",
        "studyId": studyDetail.id,
        "destination": "accessreport_" + studyDetail.id.toString(),
        "sponsorGuid": selectedStudy.sponsorGuid,
        "studyGuid": selectedStudy.studyGuid
      }
    }

    const res = yield call(api.post, "Utils/initiateUtilLambda", obj);
    if (res === "Accepted") {
      yield put(actions.finalMessage(toastId, "Report created successfull. Report will be availeble in uploads.", "success"));
    }
    else {
      yield put(actions.finalMessage(toastId, "Report failed", "warning"));
    }
    yield delay(2000);
    yield put(actions.toastClose(toastId));
  }
  catch (error) {
    yield put(actions.finalMessage(toastId, "Report failed", "error"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
    console.log("error in resp ", error);
  }
}

const chunked_array = (array, chunkSize) => {
  var R = []
  for (var i = 0, len = array.length; i < len; i += chunkSize)
    R.push(array.slice(i, i + chunkSize));
  return R;
}

function* uploadFilesV2(action) {
  const toastId = uniqueId('uploadFiles');

  try {
    const state = yield select();
    const { studyDetail } = state.root;
    const { files } = action.payload;

    const since = formatIsoDate("");
    const obj = {
      studyId: studyDetail.id,
      UploadDownload: resolveTypePayload(files[0].type),
      studyFilesSince: since
    };

    const res = yield call(getStudyPathAndCredentials, {}, obj);

    //The go through them 10 at a time to check their status
    const limit = 10
    const chunked = chunked_array(files, limit)

    for (let items of chunked) {
      let i = 0;
      for (let file of files) {
        const params = {
          studyId: studyDetail.id,
          ...res,
          file,
        };

        yield put(actions.updateUploadStatusOnUpload("LOADING", params.file));
        yield fork(uploadFileV2, params);
        i = i + 1;
      }
      yield delay(200)
    }

    //yield put(actions.studyFileUploadedReset());
    //yield put(actions.updateUploadNotification(files[0].type));
  } catch (error) {
    // dispatch a failure action to the store with the error
    console.log("error in resp ", error);
    yield put(actions.finalMessage(toastId, "Upload failed!", "error"));
    yield delay(2500);
    yield put(actions.toastClose(toastId));
  }
}

function* uploadFileV2(params) {
  //try {
  const api = yield getContext("api");
  const { file, studyId, accessKeyId, secretAccessKey, sessionToken } = params;

  const payload = {
    studyId: studyId,
    UploadDownload: file.type.toUpperCase(),
    folder: file.folder,
    fileName: file.id
  };

  const uploadFiledReference = yield call(
    api.post,
    "StudyFile/BeginUpload",
    payload
  );

  const fileResult = yield call(readUploadedFileAsArrayBuffer, file.file);
  // Generate MD5 hash as a Base64 string
  const wordArray = CryptoJS.lib.WordArray.create(fileResult); // Convert ArrayBuffer to WordArray
  const md5 = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Hex); // Generate MD5 hash in hex
  const digest = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(md5)); // Convert to Base64

  let S3PutObjectParams = {
    Bucket: uploadFiledReference.s3BucketName,
    Key: uploadFiledReference.s3Key,
    Body: file.file,
    ServerSideEncryption: "AES256",
    ContentType: file.file["type"],
    ContentMD5: digest
  };

  AWS.config.httpOptions.timeout = 0;
  AWS.config.update({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    sessionToken: sessionToken
  });

  let s3 = new S3({
    apiVersion: "2006-03-01",
    region: "eu-west-1",
    sslEnabled: true,
    signatureVersion: "v4"
  });

  try {
    const options = {
      computeChecksums: true
    };

    yield call(uploadFileToBucketV2, { s3, S3PutObjectParams, options, file: file });
    //yield put(actions.toastClose(parentToastId));

    yield call(api.put, "StudyFile/EndUpload", {
      md5: digest,
      StudyFileId: uploadFiledReference.studyFileId
    });

    //yield put(actions.updateMessage(toastId, "Uploaded " + __count + " of " + __total + " files."));
    //yield put(actions.uploadSuccess(toastId));
    yield put(actions.updateUploadStatusOnUpload("FINISHED", params.file));
    yield delay(2500);
    //yield put(actions.toastClose(toastId));

    const state = yield select();
    const { studyDetail } = state.root;

    if (
      studyDetail &&
      studyDetail.id === studyId
    ) {
      yield put(actions.getStudyFileFolders());
      yield put(actions.setStudyFileFolder([]));
    }

    AWS.config.httpOptions.timeout = 120000;
  } catch (error) {
    // dispatch a failure action to the store with the error
    AWS.config.httpOptions.timeout = 120000;
    console.log("error in resp ", error);
    //yield put(actions.finalMessage(toastId, "Upload failed!", "error"));
    yield put(actions.updateUploadStatusOnUpload("ERROR", params.file));
    yield delay(2500);
    //yield put(actions.toastClose(toastId));
  }
}

function* uploadFileToBucketV2(props) {
  const channel = yield call(uploadChannel, props.s3, props.S3PutObjectParams);

  while (true) {
    try {
      const { progress = 0, err, } = yield take(channel);

      if (err) {
        yield put(actions.updateUploadStatusOnUpload("ERROR", props.file));
      }

      yield put(actions.updateUploadProgressOnUpload(progress, props.file));

    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

function* refreshPageByType(action) {
  //const state = yield select();
  const { type, selected, id, studyFileId } = action.payload;

  if (selected !== -1 && selected !== parseInt(id, 0)) {
    yield put(actions.resetStudyDetail())
  }

  //yield put(actions.selectStudy(id, type))
  yield call(getStudy, { payload: { studyId: id, viewMode: type } })

  yield put(actions.studyToggleFileType(type))
  yield put(actions.studyFileReset())

  if (selected !== -1 && selected !== parseInt(id, 0)) {
    yield put(actions.resetFilters())
  }

  yield put(actions.getStudyFileFolders())

  if (studyFileId !== undefined) {
    yield put(actions.studyFileInitV2(id, studyFileId));
  }
}

function* refreshSasPage(action) {
  //const state = yield select();
  const { type, selected, id, sasJobId } = action.payload;

  if (selected !== -1 && selected !== parseInt(id, 0)) {
    yield put(actions.resetStudyDetail())
  }

  //yield put(actions.selectStudy(id, type))
  yield call(getStudy, { payload: { studyId: id, viewMode: type } })

  yield put(actions.sasJobReset())
  yield put(actions.getSasJobFileFolders())

  if (sasJobId !== undefined) {
    yield put(actions.sasJobInit(id, sasJobId));
  }

}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* commonSagas() {
  yield takeLatest(actions.GET_STUDIES, getStudiesSaga);
  yield takeLatest(actions.GET_STUDIES_V2, getStudiesSagaV2);
  yield takeLatest(actions.GET_STUDIES_WITH_PROJECTS, getStudiesWithProjectsSaga);
  yield takeLatest(actions.SELECT_STUDY, getStudy)

  yield takeLatest(actions.GET_SASJOBS, getSasJobsSaga);
  yield takeLatest(actions.SASJOBS_GET_PAGING, getSasJobsSagaPaging);
  yield takeLatest(actions.GET_SASJOB_FILES_FOLDERS, getSasJobFileFolders);
  //yield takeLatest(actions.GET_SASJOB_PAGING, getSasJobPaging);
  yield takeLatest(actions.STUDY_REQUEST_PERMISSION, requestStudyPermissionManager);
  yield takeLatest(actions.STUDY_FILE_STATUS_CHANGE, changeStudyFileStatus);
  yield takeLatest(actions.STUDY_FILE_TYPE_CHANGE, changeStudyFileType);
  //yield takeLatest(actions.GET_STUDY_PATH, getStudyPath);
  //yield takeLatest(actions.GET_STUDY_PATH_PAGING, getStudyFilePaging);
  //yield takeLatest(actions.STUDY_FILES_CHANGE_DATE, getStudyPath); // make it smart based on action type
  yield takeLatest(actions.GET_STUDY_FILES_FOLDERS, getStudyFileFolders);
  yield takeLatest(actions.GET_STUDY_FILES_FOLDERS_WITH_FOLDER, getStudyFileFoldersWithFolder);
  yield takeLatest(actions.FETCH_STUDY_PERMISSIONS, getStudyPermissions);
  yield takeLatest(actions.GET_STUDY_PERMISSIONS_FOR_DASHBOARD, getStudyPermissionsForDashboard);
  yield takeLatest(actions.GET_ISSUES_FOR_DASHBOARD, getIssuesForDashboard);
  yield takeLatest(actions.GET_SAS_ISSUES, getSasIssues);

  yield takeLatest(actions.FETCH_ACCESS_LOG_INFO, accessLogInfo);
  yield takeLatest(actions.FETCH_LIST_ACCESS_LOG_EXTRACTS, listAccessLogExtracts);
  yield takeLatest(actions.FETCH_ACCESS_LOG_EXTRACT_2_DB, accessLogExtract2Db);
  yield takeLatest(actions.FETCH_ACCESS_LOG_PARTITION_UPDATE, accessLogPartitionUpdate);
  yield takeLatest(actions.FETCH_ACCESS_LOG_EXTRACT, accessLogExtract);
  yield takeLatest(actions.FETCH_ADMIN_ENABLE_ACCESS_LOG, adminEnableAccessLog);

  /*
  AccessLogInfo/{studyid}
  *ListAccessLogExtracts/{studyid}
  *PUT AccessLogExtract2Db
      public class ImportAccessLogsRequestModel
      {
          public string s3Key { get; set; }
          public int studyId { get; set; }
      }
  *PUT AccessLogPartitionUpdate/{studyid}
  *AccessLogExtract/{studyId}/{year}/{month?}
  *POST AdminEnableAccessLog/{studyid}
  */

  yield takeLatest(actions.STUDY_PERMISSION_TOGGLED, assignStudyPermissions);
  yield takeLatest(actions.STUDY_UPLOAD_FILES, uploadFiles);
  yield takeLatest(actions.STUDY_UPLOAD_FILES_V2, uploadFilesV2);
  yield takeLatest(actions.STUDY_DELETE_FILES, deleteFiles);
  yield takeLatest(actions.STUDY_DELETE_ALL_FILES, deleteMultipleFiles);
  yield takeLatest(actions.STUDY_DELETE_ALL_VERSIONS, deleteAllFileVersions);
  //yield takeLatest(actions.STUDY_DOWNLOAD_ALL_FILES, downloadMultipleFiles);
  yield takeLatest(actions.STUDY_DOWNLOAD_ALL_FILES, downloadMultipleFilesV2)
  yield takeLatest(actions.STUDY_COLLECT_ALL_FILES, collectMultipleFiles);
  yield takeLatest(actions.STUDY_MIGRATE_SAS_FILES, MigrateSasFiles);
  //yield takeLatest(actions.STUDY_DOWNLOAD_ALL_SAS_FILES, downloadMultipleSasFiles);
  yield takeLatest(actions.STUDY_DOWNLOAD_ALL_SAS_FILES, downloadMultipleSasFilesV2);
  yield takeLatest(actions.STUDY_PREVIEW_FILE, previewFile);
  yield takeLatest(actions.STUDY_GET_JSON, getJson);
  yield takeLatest(actions.STUDY_GET_CONFIG_CSV, getConfigCSV);
  yield takeLatest(actions.LATEST_UPDATE_DATE, getLatestUpdateDate)
  yield takeLatest(actions.LATEST_SAS_UPDATE_DATE, getLatestSasUpdateDate)
  yield takeLatest(actions.REFRESH_STUDY_FILE_FOLDER, refreshStudyFileFolder)
  yield takeLatest(actions.REFRESH_SASJOB_FOLDER, refreshSasJobFileFolder)
  yield takeLatest(actions.PREVIEW_TEXT_FILE, previewTxtFile)
  yield takeLatest(actions.STUDY_FILE_INIT, initialize);
  yield takeLatest(actions.STUDY_FILE_INIT_V2, initializeV2);
  yield takeLatest(actions.STUDY_FILE_COMPARER_OPEN_MODAL, compareFile);
  yield takeLatest(actions.FILES_CHANGED_AFTER_DATE, filesChangedAfterDate)
  yield takeLatest(actions.SAS_FILES_CHANGED_AFTER_DATE, sasfilesChangedAfterDate)
  yield takeLatest(actions.REFRESHEVERYXMINUTES, refreshEveryXminutes)
  yield takeLatest(actions.STUDY_SHARE_ALL_FILES, shareMultipleFiles)
  yield takeLatest(actions.STUDY_SHARE_ALL_SAS_FILES, shareMultipleSasFiles)

  yield takeLatest(actions.STUDY_COPY_DIRECT_LINK, copyDirectLink)

  yield takeLatest(actions.PAGING_BUTTON_CLICKED, getStudyFileROPaging)
  yield takeLatest(actions.PAGING_BUTTON_CLICKED_SAS, getSasjobROPaging)
  yield takeLatest(actions.PAGING_BUTTON_CLICKED_RELEASED, getStudyReleaseFilePaging)

  yield takeLatest(actions.STUDY_CHANGE_FILTER, changeStatusAndFetch)
  yield takeLatest(actions.STUDY_CHANGE_VIEW_TOGGLE, changeViewAndFetch)


  yield takeLatest(actions.STUDY_FILE_DONWLOAD_SAS_JOB, downloadSasFileV2);
  yield takeLatest(actions.STUDY_ACCESS_REPORT, getStudyAccessReport)
  yield takeLatest(actions.CLEAR_SELECTED_FOLDER, clearSelectedFolder)
  yield takeLatest(actions.CLEAR_SELECTED_SAS_FOLDER, clearSelectedSasFolder)

  yield takeLatest(actions.UPDATE_POTENTIAL_UPLOADS, updatePotentialUploads)
  yield takeLatest(actions.CORRECT_ISSUES_FROM_DASHBOARD, correctIssueFromDashboard)
  yield takeLatest(actions.RESET_STUDY_FILE_RO, ResetStudyFileRO)
  yield takeLatest(actions.RESET_SAS_JOB_RO, ResetSasJobRO)
  yield takeLatest(actions.GET_ADMIN_ON_STUDY, getAdminsOnStudy)
  yield takeLatest(actions.REFRESH_PAGE_BY_TYPE, refreshPageByType)
  yield takeLatest(actions.REFRESH_SAS_PAGE, refreshSasPage)
}
