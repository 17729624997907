import React from "react";
import classNames from "classnames";
//import { withStyles } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    zIndex: 10,
    color: "#fff"
  },
  border: {
    borderBottom: '1px solid #9e9e9e2b',
    background: 'transparent',
  },
  background: {
    background: '#328cc8',
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.54)"
  },
  yellowIcon: {
    color: '#FFE000',
    top: '4px'
  }
});

const ModalTitle = (props) => {
  const { children, onClose, border, background, dark } = props;

  return (
    <DialogTitle
      sx={(theme) => ({
        margin: 0,
        padding: theme.spacing(2),
        zIndex: 10,
        color: "#fff",
        borderBottom: '1px solid #9e9e9e2b',
        background: 'transparent',
        background: '#328cc8'
      })
      }>{children}
      {/*<Typography variant="h6">{children}</Typography>*/}
      {onClose ? (
        <IconButton
          aria-label="Close"
          sx={(theme) => ({
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: "rgba(0, 0, 0, 0.54)"
          })}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
export default ModalTitle;