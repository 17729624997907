
import React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import classNames from "classnames";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
// https://reacttraining.com/react-router/web/example/auth-workflow basic no redux
// width: 200
//https://stackoverflow.com/questions/47804380/styling-the-placeholder-in-a-textfield
// define sm mediaqueri


const styles = /*theme =>*/ ({
  root: {
    width: '100%',
    position: 'relative',
    boxShadow: 'none',
    borderRadius: '10px',
  },
  tableHeader: {
    background: "white",
    color: "black",
    "&:focus": {
      outline: 0
    },
    fontSize: "0.5rem",
    textTransform: 'uppercase',
    borderRadius: '8px',
  },
  tableCellHeader: {
    fontSize: '0.5rem',
    color: 'black',
    padding: '0 1rem',
    textTransform: 'uppercase',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    border: '1px'
  },
  rowHead: {
    height: '48px',

  },
  tableCellBody: {
    fontSize: '0.6rem',
    padding: '0 1rem',
  },
  tableCellDisabled: {
    color: "rgba(0, 0, 0, 0.54)"
  },
});

const PermissionsTable = (props) => {
  const {
    permissionToggle,
    permissions,
  } = props;


  return (
    <div style={{ border: '1px solid #e0dddd', borderRadius: '8px' }}>
      <Paper sx={styles.root}>
        <Table sx={(theme) => ({
          fontFamily: theme.typography.fontFamily
        })}>
          <TableHead sx={styles.tableHeader}>
            <TableRow sx={styles.rowHead}>
              <TableCell sx={styles.tableCellHeader}>Permission</TableCell>
              <TableCell sx={styles.tableCellHeader} align="right">Description</TableCell>
              <TableCell sx={styles.tableCellHeader} align="right">
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permissions.map(row => (
              <TableRow key={row.permission}
                sx={(theme) => ({
                  '&:nth-of-type(odd)': {
                    backgroundColor: theme.palette.background.default,
                  },
                  padding: '0 1rem',
                  height: '48px',
                  borderBottom: '1px solid rgba(224, 224, 224, 0.5)',
                })}>
                <TableCell sx={{
                  ...styles.tableCellBody, // Default styles
                  ...(row.disabled && styles.tableCellDisabled)
                }}
                  component="th" scope="row">
                  {row.permission}
                </TableCell>
                <TableCell sx={{
                  ...styles.tableCellBody, // Default styles
                  ...(row.disabled && styles.tableCellDisabled)
                }} align="right">{row.description}</TableCell>
                <TableCell sx={styles.tableCellBody} align="right">
                  <Checkbox
                    id={row.permission}
                    indeterminate={false}
                    checked={row.selected}
                    disabled={row.disabled}
                    onChange={() => permissionToggle(row.permission)}
                    sx={(theme) => ({
                      color: theme.palette.primary.light,
                      "&$checked": {
                        color: theme.palette.primary.light
                      }
                    })}

                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}

                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}


PermissionsTable.propTypes = {
  permissionToggle: PropTypes.func,
  permissions: PropTypes.array
};

export default PermissionsTable;
