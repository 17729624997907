export const PERMISSION_TOGGLE = 'PERMISSION_TOGGLE';
export const PERMISSION_TOGGLE_ALL = 'PERMISSION_TOGGLE_ALL';
export const PERMISSION_RECEIVE = 'PERMISSION_RECEIVE';
export const PERMISSION_TOGGLE_MODAL = 'PERMISSION_TOGGLE_MODAL';

export const permissionToggleAll = () => ({
    type: PERMISSION_TOGGLE_ALL,
    payload: {}
});

export const permissionToggleModal = (studyId, customer) => ({
    type: PERMISSION_TOGGLE_MODAL,
    payload: { studyId, customer }
});

export const permissionReceive = permissions => ({
    type: PERMISSION_RECEIVE,
    payload: { permissions }
});

export const permissionToggle = id => ({
    type: PERMISSION_TOGGLE,
    payload: { id }
});
