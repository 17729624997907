import React from "react";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import ModalTitle from "../components/ModalTitle";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LockIcon from '@mui/icons-material/Lock';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import CreateIcon from '@mui/icons-material/Create';
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";

const Modal = (props) => {

  const location = useLocation();
  const prevLocationRef = useRef(location);

  const {
    value,
    classes,
    onClose,
    fullScreen,
    maxWidth,
    titleBorder,
    titleBackground,
    titleDark,
    title,
    staticContext,
    ...other
  } = props;

  useEffect(() => {
    //if (location !== prevLocationRef) {
    if (location !== prevLocationRef.current) {

      if (onClose) {
        onClose();
      }
      prevLocationRef.current = location; // Update ref after handling
    }
  }, [location]);

  return (
    <Dialog
      fullScreen={fullScreen}
      /*disableBackdropClick={false}*/
      disableEscapeKeyDown={false}
      /*onBackdropClick={onClose}*/
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      maxWidth={maxWidth}
      sx={{
        "& .MuiPaper-root": { ...classes.paper }
      }}
      {...other}
    >
      {!title && <ModalTitle onClose={onClose} />}
      {title && (
        <ModalTitle sx={{ margin: "0.3rem 0 0 1rem", fontSize: "1rem", color: "#9e9e9e" }} onClose={onClose} border={titleBorder} background={titleBackground}
          dark={titleDark} /*className={classes.modalHeader}*/>
          {/*<h3 className={classes.modalHeader}>{title}</h3>*/}
          {title.toUpperCase() === "UPLOAD FILES" &&
            <CloudUploadIcon sx={{ color: "#004884", top: "5px", position: "relative", marginRight: "4px" }} />
          }
          {((title.toUpperCase() === "VERIFY PHONE NUMBER") || (title.toUpperCase() === "VERIFY EMAIL")) &&
            <CheckBoxIcon sx={{ color: "#004884", top: "5px", position: "relative", marginRight: "4px" }} />
          }
          {(title.toUpperCase() === "PASSWORD CHANGE") &&
            <LockIcon sx={{ color: "#004884", top: "5px", position: "relative", marginRight: "4px" }} />
          }
          {(title.toUpperCase() === "FILE VERSIONS") &&
            <DynamicFeedIcon sx={{ color: "#004884", top: "5px", position: "relative", marginRight: "4px" }} />
          }
          {(title.toUpperCase() === "CREATE NEW PROJECT") &&
            <CreateIcon sx={{ color: "#004884", top: "5px", position: "relative", marginRight: "4px" }} />
          }
          {title}
        </ModalTitle>
      )}
      <DialogContent
        /*sx={{ root: classes.dialog }}*/
        sx={{
          "&.DialogContent-root": {
            // your root styles but with even higher CSS specificity
          }
        }}>
        {props.children}
      </DialogContent>
    </Dialog>
  );
}


Modal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Modal;
