import * as actions from "../actions";
import { isAdminPermissionModalOpen, selectPermissisonDetail, getPermissionDetail } from '../selectors/storeselectors';

import {
  takeLatest,
  call,
  put,
  getContext,
  select,
} from "redux-saga/effects";


export function* adminAassignStudyPermissions() {
  let originalPermissions = [];
  try {
    const api = yield getContext("api");
    const permissionDetail = yield select(selectPermissisonDetail);
    originalPermissions = permissionDetail.permissions;

    console.log(originalPermissions)
    const params = {
      AssignToUserId: permissionDetail.customer.customerId,
      StudyId: permissionDetail.studyId,
      permissions: permissionDetail.permissions
    };

    yield call(api.post, "StudyPermission/AssignStudyPermissions", params);

  } catch (error) {
    // set back original permissions if api fails
    yield put(actions.permissionReceive(originalPermissions));
    yield put(actions.onApiError());
  }
}


export function* adminGetStudyPermissions(action) {
  try {
    const api = yield getContext("api");

    const isModalOpen = yield select(isAdminPermissionModalOpen);
    if (isModalOpen) {

      const { customer, studyId } = action.payload;
      const res = yield call(api.get, `StudyPermission/AssignStudyPermissions/${studyId}/${customer.customerId}`);
      console.log(res)
      const permissionDetail = yield select(getPermissionDetail);
      console.log(permissionDetail)
      if (permissionDetail.studyId && permissionDetail.studyId === studyId && permissionDetail.customer && permissionDetail.customer.customerId === res.assignToUserId) {
        console.log("we are shooting of permissionReceive with", res.permissions)
        yield put(actions.permissionReceive(res.permissions));
      }

    }
  } catch (error) {
    // should write en error occured
    console.log("adminGetStudyPermissions error in resp ", error);
    yield put(actions.onApiError());
  }
}

export function* adminPermissionsSagas() {
  yield takeLatest(actions.PERMISSION_TOGGLE_MODAL, adminGetStudyPermissions);
  yield takeLatest([actions.PERMISSION_TOGGLE, actions.PERMISSION_TOGGLE_ALL], adminAassignStudyPermissions);
}
