import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as actions from "../../actions/";
import ReactDiffViewer from 'react-diff-viewer-continued';
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-sas";
import Modal from "./Modal";
import { timeDifference } from "../../utils/date";

const styles = /*theme => (*/{
  container: {
    border: "1px solid #e0dddd",
    marginTop: "1.5rem"
  },
  modalHeader: {
    margin: "0 0 0 1rem",
    //...theme.typography,
    fontSize: "1rem",
    color: "#FFE000"
  },
  paper: {
    borderRadius: "8px"
  },
  diffViewer: {
    maxEidth: "90%",
    margin: "0 auto",
    borderRadius: "8px",
    overflowX: "auto",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    boxShadow: "0 0 30px #1c1e25",
    fontSize: 10,
    fontFamily: "'Courier New', monospace"
  }
};

//

// reload current view oíf delete files or update
const FileComparerModal = () => {
  //const { sasJobroot, root } = props;
  const dispatch = useDispatch()
  const root = useSelector(state => state.root)
  const sasJobroot = useSelector(state => state.sasJob)

  let newCode, newTitle, oldCode, oldTitle, type;
  const { studyDetail } = root
  const { sasJob, Comparer } = sasJobroot

  const highlightSyntax = (str) => {
    if (str !== undefined) {
      return (
        <pre style={{ display: 'inline' }}
          dangerouslySetInnerHTML={{ __html: highlight(str, languages.sas) }}
        />
      )
    }
  }

  if (studyDetail && studyDetail.Comparer) {
    type = "studyFile"
    newCode = (studyDetail) ? studyDetail.fileContent : ""
    newTitle = (studyDetail) ? studyDetail.file.folder + "/" + studyDetail.file.fileName + " - pushed " + timeDifference(studyDetail.file.uploadDateTime) : ""
    oldCode = (studyDetail) ? studyDetail.Comparer : ""
    oldTitle = (studyDetail) ? studyDetail.compareFile.type + "/" + studyDetail.compareFile.name + " - pushed " + timeDifference(studyDetail.compareFile.created) : ""
  }

  if (sasJob && Comparer) {
    type = "sasJob"
    newCode = (sasJob) ? sasJob.sasCode : ""
    newTitle = (sasJob) ? sasJob.folder + "/" + sasJob.fileName + " - pushed " + timeDifference(sasJob.sasJobCreatedDate) : ""
    oldCode = (Comparer) ? Comparer.sasCode : ""
    oldTitle = (Comparer) ? Comparer.folder + "/" + Comparer.fileName + " - pushed " + timeDifference(Comparer.sasJobCreatedDate) : ""
  }

  return (
    <Modal
      fullScreen
      titleBorder
      onClose={(event) => {
        console.log("Modal onClose triggered");
        if (type === "sasJob") {
          dispatch(actions.fileComparerSasReset());
        } else {
          dispatch(actions.fileComparerStudyFileReset());
        }
      }}
      classes={styles}
      title={"File compare"}
      titleBackground
      maxWidth="lg"
      open={true}
    >
      <div style={styles.container}>
        <div style={styles.diffViewer}>
          {(type === "sasJob") ?
            <ReactDiffViewer
              oldValue={newCode}
              newValue={oldCode}
              splitView={true}
              showDiffOnly={false}
              renderContent={highlightSyntax}
              leftTitle={newTitle}
              rightTitle={oldTitle}
            />
            :
            <ReactDiffViewer
              oldValue={newCode}
              newValue={oldCode}
              splitView={true}
              showDiffOnly={false}
              leftTitle={newTitle}
              rightTitle={oldTitle}
            />
          }
        </div>
      </div>
    </Modal>
  );
}


export default FileComparerModal;
