import React from "react";
import PropTypes from "prop-types";
import * as actions from "../../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
//import { withStyles } from "@mui/material/styles";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import Modal from "./Modal";

const styles = theme => ({
  container: {
    border: "1px solid #e0dddd",
    marginTop: "1.5rem"
  },
  modalHeader: {
    margin: "0 0 0 1rem",
    ...theme.typography,
    fontSize: "1rem",
    color: "#FFE000"
  },
  paper: {
    borderRadius: "8px"
  }
});

// reload current view oíf delete files or update
const FileReviewModal = () => {

  const fileReview = useSelector(state => state?.fileReview)

  const { reviewLoading } = fileReview;

  return (
    <Modal
      fullScreen
      onClose={() => dispatch(actions.fileReviewReset)}
      classes={styles}
      title={"Review output"}
      titleBackground
      titleDark
      maxWidth="lg"
      open={true}
    >
      {reviewLoading && <CircularProgress size={24} />}
      {!reviewLoading && (
        <div className={styles.container}>
          <span>Testing review model</span>
          <img alt="" width="595" height="842" src={""/*imgSrc*/}>
          </img>
        </div>
      )}
    </Modal>
  );
}
/*
// we need a selector, route change should kill all modals
FileReviewModal.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  studyDetail: PropTypes.object,
  fileReview: PropTypes.object
};

function mapStateToProps(state) {
  const studyDetail = state.root.studyDetail
  const fileReview = state.fileReview
  return { studyDetail, fileReview }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)((FileReviewModal));
*/
export default FileReviewModal